import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    snackbar : {
        margin: theme.spacing.unit,
        top: "70px"
    },
    root : {
        borderRadius: "4px"
    },
    snackbarContent : {
        paddingLeft: "0",
        width: "10%"
    },
    success: {
        backgroundColor: green[600],
        borderRadius:"4px",
        padding: "12px 24px"
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        borderRadius:"4px",
        padding: "12px 24px"
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
        borderRadius:"4px",
        padding: "12px 24px"
    },
    warning: {
        backgroundColor: amber[700],
        borderRadius:"4px",
        padding: "12px 24px"
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    messageWrapper : {
        width: "90%",
        textAlign: "left"
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    }
})

class CustomSnackbar extends Component {
    render(){
        const { open, variant, message, handleClose, classes } = this.props;

        const variantIcon = {
            success: <CheckCircleIcon className={classes.icon + ' ' + classes.iconVariant} />,
            warning: <WarningIcon className={classes.icon + ' ' + classes.iconVariant} />,
            error: <ErrorIcon className={classes.icon + ' ' + classes.iconVariant} />,
            info: <InfoIcon className={classes.icon + ' ' + classes.iconVariant} />
        };

        const Icon = variantIcon[variant];

        return(
            <Snackbar
                open={open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={5000}
                onClose={handleClose}
                className={classes.snackbar}
            >
                <SnackbarContent
                    classes={{
                        root : classes[variant],
                        message : classes.messageWrapper,
                        action : classes.snackbarContent
                    }}
                    aria-describedby="client-snackbar"
                    message={
                        <div 
                            id="client-snackbar" 
                            className={classes.message}
                        >
                            {Icon}
                            <div dangerouslySetInnerHTML={{__html: message}} />
                        </div>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={handleClose}
                            className={classes.icon}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        )
    }
}

export default withStyles(styles)(CustomSnackbar);