import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Modal from '../Modal.js';
const labels = require("../../labels");

export default class CustomCheckbox extends Component{
	render(){
		const { fieldData, hasError, fieldValue, inputWrapperStyle, setValue, toggleModal, isModalOpen, readOnly, validateField, language } = this.props;
		let { errorMessage } = this.props;

		const checkboxErrorStyle = {
			margin:"5px 0 5px 34px"
		}

		const seeModalButtonStyle = {
			width:"0",  
			marginLeft:"34px", 
			marginTop:"5px"
		}

		errorMessage = (fieldValue === false && errorMessage);

		return (
			<div style={ inputWrapperStyle(fieldData.width, fieldData.type)}>
				<FormControl 
					error={hasError}
					disabled={readOnly}
				>
					<FormControlLabel
						className="checkbox"
						control={
							<Checkbox
								checked={fieldValue || false}
								name={fieldData.name}
								onChange={(e) => setValue(fieldData.name, e.target.checked)}
								onBlur={(e) => !readOnly && validateField(fieldData.name, e.target.value, fieldData.required, fieldData.validations)}
								color="secondary"
							/>
						}
						label={fieldData.label}
					/>
					{errorMessage &&
						<FormHelperText 
							style={checkboxErrorStyle}
						>{errorMessage}</FormHelperText>
					}
					{fieldData.hasModal && !readOnly && 
						<Button 
							variant="outlined"
							style={seeModalButtonStyle}
							onClick={() => toggleModal(fieldData.name)}
						>
							{labels.getLabel("see", language)}
						</Button>
					}
				</FormControl>
				
				{fieldData.hasModal && toggleModal && isModalOpen &&
					<Modal
						open={isModalOpen(fieldData.name)}
						name={fieldData.name}
						title={fieldData.modalTitle}
						text={unescape(fieldData.modalText)}
						primaryButtonText={labels.getLabel("readAndAccept", language)}
						primaryAction={() => { toggleModal(fieldData.name); setValue(fieldData.name, true); } }
						secondaryButtonText={labels.getLabel("no", language)}
						secondaryAction={() => toggleModal(fieldData.name)}
					/>
				}
			</div>
		)
	}
}