import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
const countriesList = require("../../countriesList.json");

export default class FieldWithCountry extends Component{

    getCountryCodeComponent = (submitData, fieldData, hasError, setValue, readOnly) => {
		const fieldName = fieldData.name;
		const auxName = fieldName+'-country';
		const fieldValue = submitData.formValues[fieldName];
		const value = submitData.formValues[auxName] || "PT";
		return (
			<TextField
				id={auxName}
				shrink="true"
				error={hasError}
				fullWidth={true}
				className={fieldValue ? "input-wrapper countryCode filled" : "input-wrapper countryCode"}
				select
				name={auxName}
				value={value}
				onChange={(e) => setValue([auxName], e.target.value)}
				onBlur={ (e) => !readOnly && this.props.validateField(fieldName, fieldValue, fieldData.required, fieldData.validations, value)}
				variant="outlined"
				InputProps={{
					readOnly:readOnly
				}}
			>
				{countriesList.map((country, index) => {
						return (
							<MenuItem key={index} value={country.code}>{country.code}</MenuItem>
						)
					})}
			</TextField>
		);
	}

	handleOnChange = (fieldName, value, maxLength) => {
		const valueWithoutSpaces = value.replace(/\s/g, "");
		if(maxLength === undefined || value.length <= maxLength)
			this.props.setValue(fieldName, valueWithoutSpaces);
	}

	render(){
		const {fieldData, submitData, fieldValue, inputWrapperStyle, setValue, validateField, hasError, errorMessage, readOnly} = this.props;
		const countryValue = submitData.formValues[fieldData.name+"-country"];
		const maxLength = (countryValue==="PT" || countryValue === undefined) && fieldData.type === "phone" ? 9 : undefined;

		return (
			<FormControl 
				className="input-with-country" 
				error={hasError}
				variant="outlined"  
				style={ inputWrapperStyle(fieldData.width) }
			>
				<InputLabel shrink={true} className={(fieldValue) ? "filled" : ""}>
					{fieldData.label + (fieldData.required ? " *":"")}
				</InputLabel>
				{this.getCountryCodeComponent(submitData, fieldData, hasError, setValue, readOnly)}
				<TextField 
					InputProps={{
						readOnly:readOnly
					}}
					id={fieldData.name}
					error={hasError}
					className={(fieldValue) ? "input-wrapper value filled" : "input-wrapper value"}
					value={fieldValue || ""}
					name={fieldData.name}
					type="number"
					multiline={false}
					helperText={errorMessage}
					onChange={(e) => this.handleOnChange(fieldData.name, e.target.value, maxLength)}
					onBlur={ (e) => !readOnly && validateField(fieldData.name, e.target.value, fieldData.required, fieldData.validations, (countryValue || "PT"))}
					inputProps={{
						maxLength : maxLength
					}}
					fullWidth={true}
					variant="outlined"
				/>
			</FormControl>
		)
	}
}
