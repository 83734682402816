import React, { Component } from 'react';
import '../Styles/App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TopMenu from './TopMenu.js';
import SideMenu from './SideMenu.js';
import Login from './Login.js';
import Homepage from './Homepage.js';
import DonationForm from './DonationForm.js';
import LoadingSpinner from './LoadingSpinner.js';
import CustomSnackbar from "./CustomSnackbar.js";
import Modal from './Modal';
import PDFRender from "./PDFRender";
//import Geolocation from './Geolocation.js';
import axios from 'axios';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter
} from "react-router-dom";
import Cookies from "js-cookie";
import asyncComponent from "./AsyncComponent";
const SetupPage = asyncComponent(() => import("./SetupPage"));
const UserProfileSetupPage = asyncComponent(() => import("./UserProfileSetupPage"));
const DonationDetailsPage = asyncComponent(() => import("./DonationDetailsPage"));
const TeamsCalendar = asyncComponent(() => import("./TeamsCalendar"));
const ReportComponent = asyncComponent(() => import("./Reports/ReportComponent"));
const ReportFoldersAndFiles = asyncComponent(() => import("./ReportFoldersAndFiles"));
const DefinePassword = asyncComponent(() => import("./DefinePassword"));
const Notes = asyncComponent(() => import("./Notes"));
const TeamAssignment = asyncComponent(() => import("./TeamAssignment"));
const Times = asyncComponent(() => import("./Times"));
const GoalsBuilder = asyncComponent(() => import("./GoalsBuilder"));
const BonusBuilder = asyncComponent(() => import("./BonusBuilder"));
const Bonus = asyncComponent(() => import("./Bonus"));

const host = (process.env.NODE_ENV === "development") ? require("../config.json").developerHost : require("../config.json").productionHost;
const utility_functions = require("../utility_functions.js");
const formFunctions = require("../formFunctions.js");
const labels = require("../labels.js");
const moment = require('moment');

const nacl = require("tweetnacl");
const util = require("tweetnacl-util");

const receiverPublicKey = "ljY/Gz6fV8kXftupF0OHfk8Y+GnD5LA+Zh8LhHtqA2Y=";

class App extends Component {
  constructor(props){
    super(props);

    const localStorageUserData = JSON.parse(localStorage.getItem("userData"));
    const orgId = localStorageUserData && localStorageUserData.orgId;
    const username = localStorageUserData && localStorageUserData.username;
    const localStorageEntries = this.getLocalStorageEntries(orgId, username);
    const sessionCookie = Cookies.get("f2fAppSID");

    this.state = {
      theme: {
          palette : {
              primary : {
                main :  (sessionCookie && localStorageUserData && localStorageUserData.colorTheme) || "#00214e"
              },
              secondary : {
                main : "#000"
              }
          },
          overrides:{
              /*MuiPickersToolbarButton: {
                  toolbarBtn: {
                      color: "black",
                  },
                  toolbarBtnSelected : {
                      color: "black",
                  }
              },*/
              MuiPickerDTHeader : {
                  hourMinuteLabel : {
                      flexDirection: "row"
                  }
              }
          }
      },
      isAuthenticated : sessionCookie ? true : false,
      userData : (sessionCookie && localStorageUserData) || {},
      language : (sessionCookie && localStorageUserData && localStorageUserData.language), // || (navigator.language && navigator.language.split("-")[0]),
      menuOpen : false,
      authenticationError : "",
      snackBarInfo : {
        open : false,
        message : "",
        variant: ""
      },
      isLoading : false,
      isSynching : false,
      isMobile : window.innerWidth < 768,
      localStorageFormEntries : localStorageEntries && localStorageEntries.formEntries,
      localStorageNoteEntries : localStorageEntries && localStorageEntries.noteEntries,
      localStorageTimeEntries : localStorageEntries && localStorageEntries.timeEntries,
      online : navigator.onLine,
      times : undefined
    }
  }

  componentWillMount(){
    window.addEventListener('online', () => {
      this.setLocalStorageData();
      this.setState({ online:true });
    });
    window.addEventListener('offline', () => this.setState({ online:false }));
    window.addEventListener("resize", 
      () => {
        const isMobile = window.innerWidth < 768;
        if(isMobile !== this.state.isMobile)
          this.setState({ isMobile }); 
      }
    );
    if(navigator.onLine){
      const userData = this.state.userData;
      if(Object.keys(userData).length > 0){
        this.getTimes();
        this.updateUserData(userData);
      }
    }
  }

  encrypt = (receiverPublicKey, dataToEncrypt) => {
    const ephemeralKeyPair = nacl.box.keyPair();
    const pubKeyUInt8Array = util.decodeBase64(receiverPublicKey)
    const msgParamsUInt8Array = util.decodeUTF8(dataToEncrypt)
    const nonce = nacl.randomBytes(nacl.box.nonceLength)
    const encryptedMessage = nacl.box(
      msgParamsUInt8Array,
      nonce,
      pubKeyUInt8Array,
      ephemeralKeyPair.secretKey
    )
    return {
      ciphertext: util.encodeBase64(encryptedMessage),
      ephemPubKey: util.encodeBase64(ephemeralKeyPair.publicKey),
      nonce: util.encodeBase64(nonce),
      version: "x25519-xsalsa20-poly1305"
    }
  }

  getLocalStorageEntries = (orgId, username) => {
    let entries = {
      formEntries : [],
      noteEntries : [],
      timeEntries : []
    };
    const orgEntries = JSON.parse(localStorage.getItem(orgId));
    if(orgEntries){
      const userEntries = orgEntries[username];
      if(userEntries){
        entries = {
          formEntries : userEntries.formEntries || [],
          noteEntries : userEntries.noteEntries || [],
          timeEntries : userEntries.timeEntries || [],
        } 
      }
    }
    return entries;
  }

  setLocalStorageEntry = (orgId, username, entryName, entryValues) => {
    let orgEntries = JSON.parse(localStorage.getItem(orgId));
    if(orgEntries){
      let userEntries = orgEntries[username];
      if(userEntries){
        localStorage.setItem(orgId, JSON.stringify({
          ...orgEntries,
          [username] : {
            ...userEntries,
            [entryName] : entryValues
          }
        }));
      }else{
        localStorage.setItem(orgId, JSON.stringify({
          ...orgEntries,
          [username] : {
            [entryName] : entryValues
          }
        }));
      }
    }else{
      localStorage.setItem(orgId, JSON.stringify({
        [username] : {
          [entryName] : entryValues
        }
      }));
    }
  }

  updateUserData = (userData) => {
    this.setLocalStorageData();
    utility_functions.getOrganizationData()
    .then(res => {
      const data = res.data.data;
      const language = data.language || this.state.language;
      if(language === "fr"){
        require("moment/locale/fr");
      }else if(language === "es"){
        require("moment/locale/es");
      }else if(!language || language === "pt"){
        require("moment/locale/pt");
      }

      userData.colorTheme = data.colorTheme;
      userData.orgType = data.type;
      userData.orgName = data.name;
      userData.orgId = data.id;
      userData.language = language;

      let theme = this.state.theme;
      if(userData.colorTheme)
        theme.palette.primary = {
          main: userData.colorTheme
        }

      localStorage.setItem("userData", JSON.stringify(userData));
      this.setState({ userData, theme, language });
    });
    utility_functions.getUserData(userData.username)
    .then(res => {
      userData.userImage = res.userImage;
      userData.profile = res.profile;
      userData.email = res.email;
      userData.firstName = res.firstName;
      userData.lastName = res.lastName;
      
      localStorage.setItem("userData", JSON.stringify(userData));
      this.setState({ userData });
    })
  }

  changeThemeColor = (color) => {
    const { theme } = this.state;
    theme.palette.primary.main = color;
    theme.palette.primary.contrastText = createMuiTheme().palette.getContrastText(color);
    this.setState({ theme });
  }

  getTimes = () => {
    const today = new Date();
    const todayDate = today.getFullYear()+"-"+(today.getMonth()+1)+"-"+today.getDate();
    const filters = "?filter[username][eq]="+this.state.userData.username+"&filter[startTime][gte]="+todayDate+" 00:00:00&filter[startTime][lte]="+todayDate+" 23:59:59";

    utility_functions.getTimesList(filters)
    .then(times => {
      //console.log(times);
      this.handleTimes(times);
    })
    .catch(error => {
      const { userData, language } = this.state;
      const self = this;
      const localStorageEntries = this.getLocalStorageEntries(userData.orgId, userData.username);
      this.setTimes(localStorageEntries.timeEntries);
      if(error.response && error.response.data.data 
        && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
        setTimeout(function(){ 
          self.logout();
        }, 3000);
        self.setSnackBar(
          labels.getLabel("doLoginAgain", language), 
          "warning"
        );
      }
    })
  }

  handleTimes = (times) => {
    const { userData } = this.state;
    let localStorageTimes = this.getLocalStorageEntries(userData.orgId, userData.username).timeEntries;
    
    if(localStorageTimes.length > 0){
      let todayLocalStorageTimes = localStorageTimes.filter(t => {
        const auxNow = moment().format("L");
        const auxStart = moment(new Date(t.startTime)).format("L");
        //const auxEnd = moment(new Date(t.endTime)).format("L");
        return moment(auxNow, "L").isSame(moment(auxStart, "L"))
        //&& moment(auxNow, "L").isSame(moment(auxEnd, "L"))
      });

      todayLocalStorageTimes.forEach(t => {
        let relatedTime = times.filter(time => time.id === t.id);
        if(relatedTime.length > 0){
          let existingTime = relatedTime[0];
          existingTime.startTime = t.startTime;
          existingTime.endTime = t.endTime;
        }else{
          times.unshift(t);
        }
      })
    }
  
    times.sort((a,b) =>
      ( (!a.endTime && b.endTime) ||  new Date(a.startTime).getTime() > new Date(b.startTime).getTime() ) ? -1 : (( new Date(b.startTime).getTime() > new Date(a.startTime).getTime() ) ? 1 : 0)
    );

    this.setTimes(times);
  }

  checkLocalStorage = () => {
    const { userData } = this.state;
    const localStorageEntries = this.getLocalStorageEntries(userData.orgId, userData.username);
    this.setState({
      localStorageFormEntries : localStorageEntries.formEntries,
      localStorageNoteEntries : localStorageEntries.noteEntries,
      localStorageTimeEntries : localStorageEntries.timeEntries,
    });
  }

  toggleSideMenu = () => this.setState({ menuOpen : !this.state.menuOpen });

  synchLocalStorage = () => {
    let { online, localStorageFormEntries, localStorageNoteEntries, localStorageTimeEntries, language } = this.state;
    const self = this;
    if(online){
      this.setState({ isSynching : true },
        () => {
          let promiseList = [];
          if(localStorageFormEntries != null){
            promiseList.push(this.synchFormEntries(localStorageFormEntries));
          }
          if(localStorageNoteEntries != null){
            if(localStorageNoteEntries.length > 0)
              promiseList.push(this.synchNoteEntries(localStorageNoteEntries));
          }
          if(localStorageTimeEntries != null){
            if(localStorageTimeEntries.length > 0)
              promiseList.push(this.synchTimeEntries(localStorageTimeEntries));
          }
  
          Promise.all(promiseList).then(values => {
            this.setSnackBar(
              labels.getLabel("synchSuccess", language),  
              "success"
            );
            this.checkLocalStorage();
            this.setState({ isSynching : false });
          })
          .catch( error => {
            //console.log(error)
            self.setState({ isSynching : false });
            if(error.response && error.response.data.data 
              && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
              setTimeout(function(){ 
                self.logout();
              }, 3000);
              self.setSnackBar(
                labels.getLabel("doLoginAgain", language), 
                "warning"
              );
            }
          })
        }
      );
    }else{
      this.setSnackBar(
        labels.getLabel("synchOnlyOnline", language), 
        "warning"
      );
    }
  };

  synchFormEntries = (formEntries) => {
    const { userData, language } = this.state;
    const self = this;

    let promiseList = Object.keys(formEntries).map( formId => {
      let entries = formEntries[formId];

      /*let entries =  [
        { ciphertext: '2A5we3jngJglSU2NC+UZdWX51o1yMUigsP4HdQg/SihplCETfUUhNNAcu//gSCkEEmFMKKDOkpkoozCKzywGF0PN7zdNhedykVH6pPy0lGljU8sKbkbtkRQl64OAVEbJ3l9FE2oXn/Cm8yX5XzuYn9bDPLRx4dLGNB6ToE4Fk8Ylo0ZXvKeCIFPQ4uI7Elh+Ujs5M8cDP9GIrU2Ftky6YwoLn3SVSBWQXZ959u4hhcWttvrCXjoJGMx1rgOE4TS1aiW8V1IRPCSA8dskStlGeYQwnQJmcraMO1QqAlEuP45DvvX0dRil1ErYvBeHqNt/HTN+czqtTC28XP+zsXkC1KI/+S1sj85rPpS5zUxgbITteoBuaBLn5GWqcH5LLp206qjJAj232u5OKgulHew2SElmihnNuwal0xUDcu2A/Sp5gUyLm26C7pPwU8hc2iqxqTJtyoigM3upw3yEhHj0Lt/ol7jp9+XE7KwiX3jVD3PI200Spfx5DcacddGHNcNCR/22zeEmQB9KFdCGQFV3kCRP7BXNQSoOO8/36XjyVQzBeCUqUjNfhlbs9LGb+xUDWVr506JZ/NBd4Nrp2C9AdtvrimcURMDjmrF9v+SWz/t2x5LjOWaVF5MOARcwscn6PMuh8B+pe+gSWwzURtkfC0yvsslPeMx/whEqOMw9KiYb5mvGwnTQcHOaxTL3nAOyDQiJXykf8W+5fuOlJ1/w8XRt5Gbz2XPxf3ZmcaDJp+nIbfwcYkvtcccXIR1Zuy8Z6wvWaMzxr4d+cKdDzhAbJmPKEg5hzmLoMDrmw1otCm1ajGem4X5Vw6RbV2UYoptH5ivJ5GwvZNC9NC9p1miH3NST+71EfYvdmtizPecz', 
        ephemPubKey: 'K3HGb96UzbTfJaA1kyRacgUD+5aHtzZbit5EMvlTKX4=', 
        nonce: '6fo3DbUcyhuHN/hfakpqXuxt2Yd86qwY',
        version : 'x25519-xsalsa20-poly1305',
        status: 'Complete',
        id: 'c3bf32d9-e943-42d4-bc21-999f579c6415'
       }
    ];*/
    
      console.log('entries new 5');
      console.log(entries);
      if(entries.length > 0)
        //return axios.put(`${host}/forms/${formId}/entries/encrypted`, entries, {withCredentials:true})
        return axios.put(`${host}/forms/${formId}/newentries/encrypted`, entries, {withCredentials:true})
        .then(res => {return Promise.resolve(res)})
        .catch(error => {return Promise.reject(error)})
      else
        return Promise.resolve(true); 
    });

    return Promise.all(promiseList).then(values => {
      if(values[0].response && values[0].response.data.data 
        && values[0].response.data.data.shortMessage && values[0].response.data.data.shortMessage === "NO_SESSION"){
          setTimeout(function(){ 
            self.logout();
          }, 3000);
          self.setSnackBar(
          labels.getLabel("doLoginAgain", language), 
          "warning"
        );
      }else{
        const submitFormsName = 'submittedForms' + JSON.stringify(new Date().getTime());
        localStorage.setItem(submitFormsName, JSON.stringify(formEntries));
          values.forEach((v) => {
            if(v.data && v.data.status === "success"){
              let ids = v.data.data.map(d => d.id);
              Object.keys(formEntries).forEach(formId => {
                for (var i = formEntries[formId].length -1; i >= 0; i--){
                  let entry = formEntries[formId][i];
                  if(ids.indexOf(entry.id) !== -1){
                    formEntries[formId].splice(i, 1);
                    ids.splice(ids.indexOf(entry.id), 1);
                  }   
                }
              })
            }
          });
          this.setLocalStorageEntry(userData.orgId, userData.username, "formEntries", formEntries);
      }
    });
  }

  synchNoteEntries = (noteEntries) => {
    const { userData, language } = this.state;
    const self = this;

    let promiseList = noteEntries.map( note => 
      axios.post(`${host}/notes`, note, {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
      .then(res => { return res })
      .catch(error => { return error })
    );

    return Promise.all(promiseList).then(values => {
      if(values[0].response && values[0].response.data.data 
        && values[0].response.data.data.shortMessage && values[0].response.data.data.shortMessage === "NO_SESSION"){
          setTimeout(function(){ 
            self.logout();
          }, 3000);
          self.setSnackBar(
          labels.getLabel("doLoginAgain", language), 
          "warning"
        );
      }else{
        let indexesToSplice = [];
        values.forEach((v, index) => {
          if(v.data && v.data.status === "success")
            indexesToSplice.push(index)
        });
        for (var i = indexesToSplice.length -1; i >= 0; i--)
          noteEntries.splice(indexesToSplice[i], 1);
        this.setLocalStorageEntry(userData.orgId, userData.username, "noteEntries", noteEntries);
      }
    });
  }

  synchTimeEntries = (timeEntries) => {
    const { userData, language } = this.state;
    const self = this;
    return utility_functions.addTime(timeEntries)
    .then(values => {
      let idsToSplice = values.map(v => v.id);
      const remainingTimes = timeEntries.filter(t => idsToSplice.indexOf(t.id) === -1);
      this.setLocalStorageEntry(userData.orgId, userData.username, "timeEntries", remainingTimes);
    }).catch(error => {
      if(error.response && error.response.data.data 
        && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
        setTimeout(function(){ 
          self.logout();
        }, 3000);
        self.setSnackBar(
          labels.getLabel("doLoginAgain", language), 
          "warning"
        );
      }
    });
  }

  logout = () => {
    axios.post(`${host}/users/logout`, {}, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
    .then(res => {
      if(res.data.status === "success"){
        Cookies.remove("f2fAppSID");
        localStorage.removeItem("userData");
        this.setState({
          isAuthenticated : false,
          userData : {}
        });
      }
    })
    .catch(error => {
      //console.log(error.response);
      if(error.response && error.response.status >= 500){
        this.setSnackBar(
          labels.getLabel("STATUS_500", this.state.language), 
          "error"
        );
      }else{
        Cookies.remove("f2fAppSID");
        localStorage.removeItem("userData");
        this.setState({
          isAuthenticated : false,
          userData : {}
        });
      }
    }) 
  }

  toggleTimer = () => {
    const { userData } = this.state;
    this.setState({ 
      userData : {
        ...userData, 
        timerOptional : !userData.timerOptional
      }
    });
  }

  login = (userData) => {
    console.log('lofin ');
    console.log( userData );
    userData.timerOptional = false;
    localStorage.setItem("userData", JSON.stringify(userData));
    this.setLocalStorageData();
    const localStorageEntries = this.getLocalStorageEntries(userData.orgId, userData.username);

    let theme = this.state.theme;
    if(userData.colorTheme)
      theme.palette.primary = {
        main: userData.colorTheme
      }

    const language = userData.language || this.state.language;
    if(language === "fr"){
      require("moment/locale/fr");
    }else if(language === "es"){
      require("moment/locale/es");
    }else if(!language || language === "pt"){
      require("moment/locale/pt");
    }

    this.setState({
      theme,
      isAuthenticated: true,
      userData,
      language,
      isLoading: false,
      localStorageFormEntries : localStorageEntries && localStorageEntries.formEntries,
      localStorageNoteEntries : localStorageEntries && localStorageEntries.noteEntries,
      localStorageTimeEntries : localStorageEntries && localStorageEntries.timeEntries
    });
  }

  addTime = (timeObj, isPlay) => {
    const { userData, language } = this.state;
    const self = this;
    utility_functions.addTime([timeObj])
    .then(res => {
      //console.log(res)
      let currentLocalStorageTimeEntries = this.getLocalStorageEntries(userData.orgId, userData.username).timeEntries;
      let auxCurrentLocalStorageTimeEntries = currentLocalStorageTimeEntries.filter(t => t.id !== timeObj.id);
      this.setLocalStorageEntries(userData.orgId, userData.username, "timeEntries", auxCurrentLocalStorageTimeEntries);
      this.checkLocalStorage();
    }).catch(error => {
      //console.log(error)
      self.setLocalStorageTimeEntry(timeObj, isPlay);
      if(error.response && error.response.data.data 
        && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
        setTimeout(function(){ 
          self.logout();
        }, 3000);
        self.setSnackBar(
          labels.getLabel("doLoginAgain", language), 
          "warning"
        );
      }
    });
  }

  setTimes = (times) => this.setState({ times });

  setLocalStorageTimeEntry = (timeObj, isPlay) => {
    const { userData } = this.state;
    let currentLocalStorageTimeEntry = this.getLocalStorageEntries(userData.orgId, userData.username).timeEntries;
    
    if(isPlay){
      currentLocalStorageTimeEntry.push(timeObj);
    }else{
      const lastIndex = currentLocalStorageTimeEntry.length-1;
      let current = currentLocalStorageTimeEntry[lastIndex];
      if(current){
        current = {...current, endTime : timeObj.endTime};
        currentLocalStorageTimeEntry[lastIndex] = current;
      }else{
        currentLocalStorageTimeEntry.push(timeObj);
      }
    }

    this.setLocalStorageEntry(userData.orgId, userData.username, "timeEntries", currentLocalStorageTimeEntry);
    this.checkLocalStorage();
  }

  setLocalStorageFormEntry = (formId, formEntry) => {
    const { userData } = this.state;
    let currentLocalStorageFormEntries = this.getLocalStorageEntries(userData.orgId, userData.username).formEntries;

    const encryptedEntry = {
      ...this.encrypt(receiverPublicKey, JSON.stringify(formEntry)),
      status : formEntry.status,
      id: formEntry.id
    }

		if(currentLocalStorageFormEntries != null){
			if(currentLocalStorageFormEntries[formId] !== undefined){
				currentLocalStorageFormEntries[formId].push(encryptedEntry);
			}else{
				currentLocalStorageFormEntries = {
					...currentLocalStorageFormEntries,
					[formId]: [encryptedEntry]
				};
			}
		}else{
			currentLocalStorageFormEntries = {
				[formId]: [encryptedEntry]
			};
		}

    //console.log(currentLocalStorageFormEntries);
    this.setLocalStorageEntry(userData.orgId, userData.username, "formEntries", currentLocalStorageFormEntries);
		this.checkLocalStorage();
  }

  setLocalStorageNoteEntry = (noteEntry) => {
    const { userData, language } = this.state;
    let newLocalStorageNoteEntry = this.getLocalStorageEntries(userData.orgId, userData.username).noteEntries;
    newLocalStorageNoteEntry.push(
      //this.encrypt(receiverPublicKey, JSON.stringify(noteEntry))
      noteEntry
    );
    this.setLocalStorageEntry(userData.orgId, userData.username, "noteEntries", newLocalStorageNoteEntry);    
    this.setSnackBar(
      labels.getLabel("doLoginAgain", language), 
      "warning"
    );
    this.checkLocalStorage();
  }

  setLocalStorageData = () => {
    formFunctions.getForm()
    .then(formObj => {
      localStorage.setItem("formObj", JSON.stringify(formObj));
    });
  }
  
  toggleLoading = () => this.setState({ isLoading : !this.state.isLoading },() => {console.log('toggling')});

  isUserAutenticathed = (state) => {
    const { 
      isAuthenticated, 
      menuOpen, 
      theme, 
      isMobile, 
      localStorageFormEntries,
      localStorageNoteEntries,
      localStorageTimeEntries,
      isSynching,
      online,
      times,
      userData,
      language
    } = state;
    const resetToken = utility_functions.getURLParameterByName("token");
    const isPdf = document.location.pathname === "/pdf";

    const pdfAccess = (
      <Route 
        path="/pdf"
        render={() => {
          return (
            <PDFRender />
          );
        }}
      />
    );

    const publicAccess = (
      <Switch>
        <Route
          path="/login"
          render={() => {
            return (
              <Login
                toggleLoading={this.toggleLoading}
                login={this.login}
                language={language}
              />
            );
          }}
        />
        {resetToken &&
          <Route
            path="/newPassword"
            render={({history}) => {
              return (
                <DefinePassword
                  token={resetToken}
                  history={history}
                  language={language}
                />
              );
            }}
          />
        }
        <Redirect from="/" to={resetToken ? "/newPassword" : "/login"} />
      </Switch>
    );
    
    const privateAccess = (
      <div className="wrapper">
        <TopMenu 
          userData={userData} 
          openMenu={this.toggleSideMenu} 
          setSnackBar={this.setSnackBar} 
          localStorageFormEntries={localStorageFormEntries}
          localStorageNoteEntries={localStorageNoteEntries}
          localStorageTimeEntries={localStorageTimeEntries}
          synchLocalStorage={this.synchLocalStorage}
          getLocalStorageEntries={this.getLocalStorageEntries}
          checkLocalStorage={this.checkLocalStorage}
          isSynching={isSynching}
          times={times}
          setTimes={this.setTimes}
          addTime={this.addTime}
          language={language}
        />
        <SideMenu 
          userData={userData} 
          open={menuOpen} 
          toggleSideMenu={this.toggleSideMenu}
          online={online}
          logout={this.logout}
          language={language}
        />
        <Switch>
          {document.location.pathname === "/" || document.location.pathname === "/login"
            ? <Redirect from={document.location.pathname} to="/home" />
            : null
          }
          <Route 
            path="/home"
            render={({ history }) => {
              return (
                <Homepage 
                  history={history}
                  userData={userData}
                  themeColors={theme.palette}
                  setSnackBar={this.setSnackBar}
                  getLocalStorageEntries={this.getLocalStorageEntries}
                  checkLocalStorage={this.checkLocalStorage}
                  online={online}
                  setTimes={this.setTimes}
                  getTimes={this.getTimes}
                  times={times}
                  language={language}
                  setLocalStorageNoteEntry={this.setLocalStorageNoteEntry}
                  logout={this.logout}
                />
              );
            }}
          />
          {userData.profile === "Administrator" &&
            <Route 
              path="/setup"
              render={({history}) => {
                return (
                  <SetupPage 
                    history={history}
                    logout={this.logout}
                    themeColors={theme.palette}
                    setSnackBar={this.setSnackBar}
                    language={language}
                    changeThemeColor={this.changeThemeColor}
                    toggleTimer={this.toggleTimer}
                  />
                );
              }}
            />
          }
          <Route 
            path="/user-setup"
            render={({history}) => {
              return (
                <UserProfileSetupPage 
                  history={history}
                  userData={userData}
                  logout={this.logout}
                  language={language}
                  setSnackBar={this.setSnackBar}
                />
              );
            }}
          />
          <Route
            path="/donor-profile"
            render={({ history, location }) => {
              return (
                <DonationDetailsPage
                  key={location.search}
                  location={location}
                  history={history}
                  themeColors={theme.palette}
                  setSnackBar={this.setSnackBar}
                  logout={this.logout}
                  userData={userData}
                  language={language}
                />
              );
            }}
          />
          <Route
            path="/form"
            render={() => {
              return (
                <DonationForm 
                  userData={userData}
                  isMobile={isMobile} 
                  themeColors={theme.palette}
                  setSnackBar={this.setSnackBar}
                  toggleLoading={this.toggleLoading}
                  getLocalStorageEntries={this.getLocalStorageEntries}
                  setLocalStorageEntries={this.setLocalStorageEntry}
                  setLocalStorageFormEntry={this.setLocalStorageFormEntry}
                  checkLocalStorage={this.checkLocalStorage}
                  language={language}
                  online={online}
                  logout={this.logout}
                />
              );
            }}
          />
          {userData.profile !== "Recruiter" &&
            <Route
              path="/reports-folders"
              render={({ history }) => {
                return (
                  <ReportFoldersAndFiles
                    history={history}
                    userData={userData}
                    language={language}
                    logout={this.logout}
                    setSnackBar={this.setSnackBar}
                  />
                );
              }}
            />
          }
          {userData.profile !== "Recruiter" &&
            <Route
              path="/report"
              render={({ history }) => {
                return (
                  <ReportComponent
                    history={history}
                    toggleLoading={this.toggleLoading}
                    themeColors={theme.palette}
                    setSnackBar={this.setSnackBar}
                    userData={userData}
                    language={language}
                    logout={this.logout}
                  />
                );
              }}
            />
          }
          {userData.profile !== "Recruiter" &&
            <Route
              path="/teams"
              render={() => {
                return (
                  <TeamAssignment 
                    setSnackBar={this.setSnackBar}
                    userData={userData}
                    language={language}
                    logout={this.logout}
                  />
                );
              }}
            />
          }
          <Route
            path="/calendar"
            render={({ history }) => {
              return (
                <TeamsCalendar 
                  setSnackBar={this.setSnackBar}
                  userData={userData}
                  themeColors={theme.palette}
                  language={language}
                  history={history}
                  logout={this.logout}
                />
              );
            }}
          />
          <Route
            path="/entriesNotes"
            render={() => {
              return (
                <Notes 
                  themeColors={theme.palette}
                  isMobile={isMobile}
                  userData={userData}
                  setSnackBar={this.setSnackBar}
                  logout={this.logout}
                  checkLocalStorage={this.checkLocalStorage}
                  online={online}
                  language={language}
                  setLocalStorageNoteEntry={this.setLocalStorageNoteEntry}
                />
              );
            }}
          />
          {userData.profile !== "Recruiter" &&
            <Route
              path="/times"
              render={() => {
                return (
                  <Times
                    language={language}
                    logout={this.logout}
                    setSnackBar={this.setSnackBar}
                    themeColors={theme.palette}
                  />
                );
              }}
            />
          }
          {userData.profile !== "Recruiter" && userData.profile !== "Team Leader" &&
              <Route
                path="/bonus"
                render={() => {
                  return (
                    <Bonus 
                      themeColors={theme.palette}
                      language={language}
                      setSnackBar={this.setSnackBar}
                      logout={this.logout}
                    />
                  );
                }}
              />
            }
            {userData.profile !== "Recruiter" && userData.profile !== "Team Leader" &&
              <Route
                path="/bonusBuilder"
                render={({ history }) => {
                  return (
                    <BonusBuilder
                      themeColors={theme.palette}
                      setSnackBar={this.setSnackBar}
                      language={language}
                      history={history}
                      logout={this.logout}
                    />
                  );
                }}
              />
          }
          {userData.profile !== "Recruiter" && userData.profile !== "Team Leader" &&
            <Route
              path="/goalsBuilder"
              render={({ history }) => {
                return (
                  <GoalsBuilder 
                    themeColors={theme.palette}
                    language={language}
                    toggleLoading={this.toggleLoading}
                    history={history}
                    logout={this.logout}
                    setSnackBar={this.setSnackBar}
                  />
                );
              }}
            />
          }
          <Route
            path="/permissionIntructions"
            render={({ history }) => {
              return (
                <Modal 
                    open={true}
                    title={labels.getLabel("changeLocationSettings", language)}
                    text={
                      "<div><div><p>No Safari:</p><ul style='list-style-type: decimal;'><li>Ir a <strong>DefiniÃ§Ãµes</strong></li><li><strong>Geral</strong></li><li>Em baixo, encontre <strong>Repor</strong></li><li><strong>Repor localizaÃ§Ã£o e privacidade</strong></li></ul></div><div><p>No Chrome:</p><ul style='list-style-type: decimal;'><li>Clique no <strong>cadeado</strong> do lado direito do url </li><li><strong>DefiniÃ§Ãµes de sites</strong></li><li>Em PermissÃµes, encontre <strong>Acesso Ã  localizaÃ§Ã£o</strong></li><li>Escolha <strong>Permitir</strong></li></ul></div></div>"
                    }
                    primaryButtonText="OK"
                    primaryAction={() => history.goBack()}
                />
              );
            }}
          />
          {/*<Route
            path="/locations"
            render={() => {
              return (
                <Geolocation />
              );
            }}
          />*/}
        </Switch>
      </div>
    );

    return (isPdf) ? pdfAccess : (isAuthenticated && !resetToken) ? privateAccess : publicAccess
  }

  setSnackBar = (message, variant) => {
    this.setState({
      snackBarInfo : {
        open : true,
        message : message,
        variant : variant
      }
    });
  }

  closeSnackBar = () => {
    this.setState({
      snackBarInfo : {
        open : false,
        message : '',
        variant : ''
      }  
    });
  }

  render() {
    const { snackBarInfo, theme, isLoading } = this.state;
    return (
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <BrowserRouter basename="/">
          <div className="App">
            {isLoading && 
              <LoadingSpinner />
            }
            {this.isUserAutenticathed(this.state)}
            <CustomSnackbar 
              open={snackBarInfo.open} 
              message={snackBarInfo.message}
              variant={snackBarInfo.variant}
              handleClose={this.closeSnackBar} 
            />
          </div>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;