import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import axios from 'axios';
//import SearchSuggestions from '../SearchSuggestions.js';
//import DeleteIcon from '@material-ui/icons/Clear';
const host = (process.env.NODE_ENV === "development") ? require("../../config.json").developerHost : require("../../config.json").productionHost;
const labels = require("../../labels");
const utilityFunctions = require("../../utility_functions");

class CreateNoteModal extends Component{
    initialState = {
        noteData : {},
        activeSearch : false,
        searchValue : "",
        searchSuggestions : [],
    }

    constructor(){
        super();
        this.state = {
            ...this.initialState
        }
        this.relatedRecordNode = React.createRef();
        this.fileInput = React.createRef();
    }

    handleChange = (name, value) => {
        this.setState({
            noteData : {
                ...this.state.noteData,
                [name] : value
            }
        })
    }

    handleSearch = (value) => {
        this.setState({ searchValue : value });
        if(value !== ""){
            utilityFunctions.getSearchResult(value, false)
            .then( suggestions => this.setState({ searchSuggestions : suggestions }) );
        }
    }

    toggleSearch = () => 
        this.setState({ 
            searchSuggestions : [],
            activeSearch : !this.state.activeSearch 
        });

    selectSuggestion = (suggestion) => {
        this.setState({
            searchValue : suggestion.value,
            noteData : {
                ...this.state.noteData,
                "relatedRecord" : suggestion.id
            }
        })
    }

    handleClose = (newNote) => {
        this.setState({
            ...this.initialState
        });
        //console.log(newNote)
        this.props.handleClose(newNote);
    }

    handleSubmit = () => {
        let { noteData } = this.state;
        const { userTeamId, language, setLocalStorageNoteEntry } = this.props;
        if(userTeamId !== null) noteData = {...noteData, teamId:userTeamId };

        axios.post(`${host}/notes`, noteData, {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            //console.log(res);
            this.handleClose(res.data.data);
        })
        .catch(error => {
            this.handleClose();
            setLocalStorageNoteEntry(noteData);
            const self = this;
            if(error.response && error.response.data.data 
                && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
                setTimeout(function(){ 
                    self.props.logout();
                }, 3000);
                this.props.setSnackBar(
                    labels.getLabel("doLoginAgain", this.props.language), 
                    "warning"
                );
            }else if(error.response && error.response.status >= 500){
                this.props.setSnackBar(
                    labels.getLabel("STATUS_500", language), 
                    "error"
                );
            }
        })
    }

    /*handleFileChange = event => {
        const { files } = event.target;
        const { noteData } = this.state;
        if(files.length > 0){
            const file = files[0];
            let currentFiles = noteData.files || [];
            let reader  = new FileReader();
            reader.onloadend = () => {
                currentFiles.push({name:file.name, value:reader.result});
                this.setState({ noteData : {...noteData, files : currentFiles} });
            }
            reader.readAsDataURL(file);
            this.fileInput.current.value = "";
        }
    }

    removeFile = index => {
        const { noteData } = this.state;
        let currentFiles = noteData.files;
        currentFiles.splice(index, 1);
        this.setState({ noteData : {...noteData, files : currentFiles} });
    }*/

    render(){
        const { 
            open,  
            fullScreen,
            //online,
            language
        } = this.props;
        const {
            noteData,
            /*searchValue,
            searchSuggestions*/
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={() => this.handleClose(false)}
                fullScreen={fullScreen}
                className="modal"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {labels.getLabel("createNote", language)}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        name="title"
                        value={noteData.title || ""}
                        onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                        margin="dense"
                        id="subject"
                        label={labels.getLabel("Subject", language)}
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    {/*online &&
                        <div style={{ width:"100%"}}>
                            <TextField 
                                id="relatedRecord"
                                value={searchValue}
                                label={labels.getLabel("Search", language)}
                                margin="dense"
                                type="text"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => this.handleSearch(e.target.value)}
                                onBlur={() => setTimeout(() => this.toggleSearch(), 250) }
                                inputRef={node => this.relatedRecordNode = node}
                            />
                            <SearchSuggestions 
                                open={searchSuggestions.length > 0} 
                                searchSuggestions={searchSuggestions}
                                anchorEl={this.relatedRecordNode}
                                onSuggestionClick={this.selectSuggestion}
                            />
                        </div>
                    */}
                    <TextField
                        margin="dense"
                        multiline
                        rows="8"
                        id="text"
                        label={labels.getLabel("Note", language)}
                        name="body"
                        value={noteData.body || ""}
                        onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                        type="text"
                        fullWidth
                        variant="outlined"
                    />

                    {/*<div className="notes-file-upload">
                        <div>
                            <Button 
                                onClick={() => this.fileInput.current.click()}
                                variant="contained"
                                color="primary"
                            >
                                Adicionar Ficheiros
                            </Button>
                            <input 
                                ref={this.fileInput}
                                type="file" 
                                onChange={this.handleFileChange}
                            />
                        </div>
                        <ul>
                            {noteData.files && noteData.files.map((f, index) => (
                                <li key={f.name+index}>
                                    {f.name}
                                    <DeleteIcon
                                        className="delete-icon"
                                        onClick={() => this.removeFile(index)} 
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>*/}

                </DialogContent>
                <DialogActions className="modal-buttons-wrapper">
                    <Button 
                        onClick={() => this.handleClose(false)}
                        variant="outlined"
                    >
                        {labels.getLabel("cancel", language)}
                    </Button>
                    <Button 
                        onClick={this.handleSubmit} 
                        color="primary"
                        variant="contained"
                    >
                        {labels.getLabel("create", language)}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withMobileDialog()(CreateNoteModal);