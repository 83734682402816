import React, { Component } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

export default class Amount extends Component{
	amountStyle = (selectedAmount, inputAmount, themeColors, wrapper) => {
		const activeAmountWrapperStyle = {
			backgroundColor : themeColors.primary.main,
			color: themeColors.primary.contrastText,
			borderColor : themeColors.primary.main
		}
		const activeAmountStyle = {
			backgroundColor : themeColors.primary.main,
			color: themeColors.primary.contrastText
		}

		if(parseInt(selectedAmount, 10) === parseInt(inputAmount, 10)){
			if(wrapper === true)
				return activeAmountWrapperStyle;
			else
				return activeAmountStyle;
		} 
	}

	handleFixedAmountClick = (value) => {
		const {setValue, validateField, fieldData} = this.props;
		setValue("fixed-amount", value);
		validateField("amount", value, false, fieldData.validations["amount"], null);
	}

	validateOpenAmount = (value) => {
		const {fieldData, validateField, fixedAmountSelected} = this.props;
		if(!fixedAmountSelected || (fixedAmountSelected && value)){
			validateField(fieldData.name, value, fieldData.required, fieldData.validations, null)
		}
	}

	render(){
		const {fieldData, inputWrapperStyle, setValue, validateField, openAmountSelected, fixedAmountSelected, hasError, errorMessage, themeColors} = this.props;

		return(
			<div style={inputWrapperStyle(fieldData.width)}>
				{fieldData.picklistValues.map((amountValue, index) => {
					if(amountValue.isOther){
						return (
							<TextField 
								key="open-amount" 
								id="open-amount" 
								type="number"
								error={hasError}
								className={openAmountSelected ? "input-wrapper filled" : "input-wrapper"}
								value={openAmountSelected || ""}
								name={fieldData.name}
								multiline={false}
								label={amountValue.label}
								helperText={errorMessage}
								onChange={(e) => setValue("open-amount", e.target.value)}
								onBlur={(e) => this.validateOpenAmount(e.target.value)}
								fullWidth={true}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment variant="outlined" position="end" className="input-adornment">
											€
										</InputAdornment>
									) 
								}}
							/>								
						)
					}else return (
						<div 
							key={index} 
							className="amount-box" 
							style={this.amountStyle(fixedAmountSelected, amountValue.value, themeColors, true)}
							onClick={() => document.getElementById(amountValue.value).click()}
						>
							<div 
								className={"value "+(!amountValue.label ? "fullWidth" : "")}
								style={this.amountStyle(fixedAmountSelected, amountValue.value, themeColors, false)}
							>
								<input 
									type="radio" 
									checked={amountValue.value === fixedAmountSelected}
									value={amountValue.value} 
									name={fieldData.name} 
									id={amountValue.value} 
									onChange={(e) => this.handleFixedAmountClick(e.target.value, setValue, validateField)}
								/>
								<label>{amountValue.value}€</label>
							</div>
							{amountValue.label &&
								<div className="description">
									<div className="text">{amountValue.label}</div>
								</div>
							}
						</div>	
					)
				})}
			</div>
		)
	}
}