import React, {Component} from 'react';
import LoadingSpinner from './LoadingSpinner.js';
import asyncComponent from "./AsyncComponent";
const utilityFunctions = require("../utility_functions.js");
const PDFDocument = asyncComponent(() => import("./PDFs/PDFDynamic"));

export default class PDFRender extends Component{
    state = {
        values : [],
        auxValues : []
    }

    componentWillMount(){
        this.getData();
    }

    getSignature = (jSignatureBase30, callback) => {
        if(jSignatureBase30){
            let jSigHelper = require("../jSignatureHelper.js");
            let convertedValue = jSigHelper.jSigHelper.base30toBase64SVG(jSignatureBase30.split(",")[1]);

            const width = 600;
            const height = 200;
            var canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            var context = canvas.getContext('2d');
            var image = document.createElement('img');
            image.onload = function () { 
                context.clearRect(0, 0, width, height);
                context.drawImage(image, 0, 0, width, height);
                var convertedSignatureValue = canvas.toDataURL('image/jpg');
                callback(convertedSignatureValue);
            };
            image.src = "data:"+convertedValue;
        }else{
            callback("");
        }
    }

    getData = () => {
        const entries = (utilityFunctions.getURLParameterByName("id") !== null && [utilityFunctions.getURLParameterByName("id")]) || JSON.parse(localStorage.getItem('pdfIds'));

        if(entries){
            entries.forEach((id, index) => {
                utilityFunctions.getPDFFormEntry(id, ["recruiter", "form"])
                .then(entryObj => {
                    let values = this.state.auxValues;

                    const recruiterData = entryObj.relationships.recruiter;
                    const newValue = {
                        ...entryObj.attributes.formValues, 
                        recruiter : recruiterData.firstName+' '+recruiterData.lastName,
                        date : utilityFunctions.getFormattedDate(entryObj.attributes.date),
                    }
                    values.push(newValue);

                    this.getSignature(newValue.signature,
                        (convertedSignatureValue) => {
                            let existingValues = this.state.auxValues;

                            if(existingValues[index])
                                existingValues[index].signature = convertedSignatureValue;

                            this.setState({
                                values : existingValues
                            });
                        }
                    );

                    let formSectionsState = this.state.formSections;
                    let pdfTemplateState = this.state.pdfTemplate;
                    let directDebitConditions = this.state.directDebitConditions;
                    let formIdState = this.state.formId;

                    if(!formSectionsState){
                        formIdState = entryObj.relationships.form.id;
                        let form = JSON.parse(entryObj.relationships.form.form_schema);
                        let formSections =  form.formSections;
                        let DirectDebitConditions = formSections.filter(f => f.name === "Direct Debit Field")[0].fields[0].modalText;
                        let auxFormSections = formSections.filter(f => 
                            f.name !== "Direct Debit Field" && f.name !== "Data Authorization Field" & f.name !== "Signature Field"
                        );
                        formSectionsState = auxFormSections;
                        directDebitConditions = DirectDebitConditions;
                    }

                    if(!pdfTemplateState){
                        pdfTemplateState = JSON.parse(entryObj.relationships.form.pdf_template_data);
                    }

                    this.setState({ 
                        formSections : formSectionsState,
                        directDebitConditions : directDebitConditions,
                        pdfTemplate : pdfTemplateState,
                        formId : formIdState,
                        auxValues : values 
                    });
                });
            });
        }
    }

    render(){
        const { formSections, formId, values, pdfTemplate, directDebitConditions } = this.state;
        let allDataSet = false;
        const entries = (utilityFunctions.getURLParameterByName("id") !== null && [utilityFunctions.getURLParameterByName("id")]) || JSON.parse(localStorage.getItem('pdfIds'));
        if(!allDataSet && entries && values && values.length === entries.length){
            let hasFinished = values.filter(v => 
                v.signature.indexOf("jsignature") !== -1
            ).length === 0;
            allDataSet = hasFinished;
        }

        return (
            Boolean(allDataSet && formSections && formId && pdfTemplate)
            ?   <PDFDocument
                    formSections={formSections}
                    pdfTemplate={pdfTemplate}
                    directDebitConditions={directDebitConditions}
                    values={values}
                />
            :   <div>
                    <LoadingSpinner
                        text={"Wait a moment. The document will be displayed soon."}
                    />
                </div>
        )
    }
}