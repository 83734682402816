import * as ibantools from "ibantools";
import * as EmailValidator from "email-validator";
import moment from 'moment';

const validateIban = (iban, defaultErrorMessage) => {
    let msg = "";
    if (ibantools.isValidIBAN(iban)) {
      return {
        valid: true,
        errorMessage: msg
      };
    } else {
      msg = defaultErrorMessage ? defaultErrorMessage : "invalidIBAN";
      return {
        valid: false,
        errorMessage: msg
      };
    }
};

const validateFiscalId = (fiscalId, defaultErrorMessage, country, validStartingDigits) => {
    let msg = "";
    if (!country || country.toLowerCase() === "pt") {
      if (validaContribuinte(fiscalId, validStartingDigits) && fiscalId.length === 9) {
        return {
          valid: true,
          errorMessage: msg
        };
      } else {
        msg = defaultErrorMessage ? defaultErrorMessage : "invalidPTFiscalId";
        return {
          valid: false,
          errorMessage: msg
        };
      }
    } else {
      if (fiscalId !== "") {
        return {
          valid: true,
          errorMessage: msg
        };
      } else {
        msg = "mandatoryField";
        return {
          valid: false,
          errorMessage: msg
        };
      }
    }
};

const validaContribuinte = (contribuinte, validStartingDigits) => {
    // algoritmo de validação do NIF de acordo com
    // http://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal
  
    if (
      contribuinte &&
      (validStartingDigits == null ||
        validStartingDigits.includes(contribuinte.substring(0, 1)))
    ) {
      var temErro = 0;
      var comparador;
  
      var check1 = contribuinte.substr(0, 1) * 9;
      var check2 = contribuinte.substr(1, 1) * 8;
      var check3 = contribuinte.substr(2, 1) * 7;
      var check4 = contribuinte.substr(3, 1) * 6;
      var check5 = contribuinte.substr(4, 1) * 5;
      var check6 = contribuinte.substr(5, 1) * 4;
      var check7 = contribuinte.substr(6, 1) * 3;
      var check8 = contribuinte.substr(7, 1) * 2;
  
      var total =
        check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
      var divisao = total / 11;
      var modulo11 = total - parseInt(divisao, 10) * 11;
      if (modulo11 === 1 || modulo11 === 0) {
        comparador = 0;
      } else {
        // excepção
        comparador = 11 - modulo11;
      }
  
      var ultimoDigito = contribuinte.substr(8, 1) * 1;
      if (ultimoDigito !== comparador) {
        temErro = 1;
      }
  
      if (temErro === 1) {
        return false;
      }
      return true;
    } else {
      return false;
    }
};

const validateEmail = (email, defaultErrorMessage) => {
  let msg = "";
  if (EmailValidator.validate(email)) {
    return { valid: true, errorMessage: msg };
  } else {
    msg = defaultErrorMessage ? defaultErrorMessage : "invalidEmail";
    return { valid: false, errorMessage: msg };
  }
};

const validatePhone = (value, defaultErrorMessage, country, isMobile) => {
  let msg = "";
  let validPortugueseOperators = ['91', '92', '93', '96'];

  if (!country || country.toLowerCase() === "pt") {
    if (value.length === 9 && (!isMobile || (isMobile && validPortugueseOperators.indexOf(value.substring(0, 2)) !== -1 ))) {
      return {
        valid: true,
        errorMessage: msg
      };
    } else {
      msg = defaultErrorMessage ? defaultErrorMessage : "phonePTdigitsError";
      return {
        valid: false,
        errorMessage: msg
      };
    }
  } else {
    if (value !== "") {
      return {
        valid: true,
        errorMessage: msg
      };
    } else {
      msg = "mandatoryField";
      return {
        valid: false,
        errorMessage: msg
      };
    }
  }
};

const validateCitizenCard = (value, defaultErrorMessage, country) => {
  let msg = "";
  if (!country || country.toLowerCase() === "pt") {
    if (value.length === 8) {
      return {
        valid: true,
        errorMessage: msg
      };
    } else {
      msg = defaultErrorMessage ? defaultErrorMessage : "citizenCardPTdigitsError";
      return {
        valid: false,
        errorMessage: msg
      };
    }
  } else {
    if (value !== "") {
      return {
        valid: true,
        errorMessage: msg
      };
    } else {
      msg = "mandatoryField";
      return {
        valid: false,
        errorMessage: msg
      };
    }
  }
}

const validatePostalCode = (value, defaultErrorMessage, country) => {
  defaultErrorMessage = defaultErrorMessage ? defaultErrorMessage : "invalidPTpostalCode";
  const regexPostalCodePT = "^\\d{4}-\\d{3}?$";

  if(!country || country.toLowerCase() === 'portugal')
    return validateRegex(value, regexPostalCodePT, defaultErrorMessage);
};

const validateAmount = (amount, defaultErrorMessage, minAmount) => {
  //console.log(amount, minAmount);

  if(amount){
    if(amount >= minAmount)
      return { valid:true, errorMessage: ""};
    else
      return { valid:false, errorMessage: defaultErrorMessage ? defaultErrorMessage : "minAmountError" };
  }else{
    return { valid:false, errorMessage: defaultErrorMessage ? defaultErrorMessage : "amountError" };   
  }
};

const validateRegex = (value, regexPattern, errorMessage) => {
  var re = new RegExp(regexPattern);
  //console.log(re);
  if (re.test(value)) {
    return { valid: true, errorMessage: "" };
  } else {
    return { valid: false, errorMessage: errorMessage };
  }
};

const validateBirthdate = (birthdate, defaultErrorMessage, minAge) => {
  const age = moment().diff(birthdate, 'years');
  if(age < minAge){
    return { valid:false, errorMessage: defaultErrorMessage };
  }else{
    return { valid:true, errorMessage: ""};
  }
}
 
const validations = {
  iban: (iban, defaultErrorMessage) => validateIban(iban, defaultErrorMessage),
  fiscalId: (fiscalId, defaultErrorMessage, country) => validateFiscalId(fiscalId, defaultErrorMessage, country),
  citizenCard: (citizenCard, defaultErrorMessage, country) => validateCitizenCard(citizenCard, defaultErrorMessage, country),
  email: (email, defaultErrorMessage) => validateEmail(email, defaultErrorMessage),
  phone: (value, defaultErrorMessage, country, isMobile) => validatePhone(value, defaultErrorMessage, country, isMobile),
  postalCode: (value, defaultErrorMessage, country) => validatePostalCode(value, defaultErrorMessage, country),
  regex: (value, regexPattern, errorMessage) => validateRegex(value, regexPattern, errorMessage),
  minAmount: (amount, defaultErrorMessage, minAmount) => validateAmount(amount, defaultErrorMessage, minAmount),
  birthdate: (birthdate, defaultErrorMessage, minAge) => validateBirthdate(birthdate, defaultErrorMessage, minAge)
};

export default validations;