const Joi = require("joi-browser");
const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(.+)$/;
const urlRegex = /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)$/;

const bonusConditionsSchema = Joi.object({
  variable: Joi.string(),
  operator: Joi.string()
    .valid(["eq", "gt", "gte", "lt", "lte", "ne"])
    .required(),
  value: Joi.any().required()
});

const schemas = {
  stripe : {
    stripeSecretKey: Joi.string().max(64),
    stripePublishableKey: Joi.string().max(64),
    stripeSuccessUrl: Joi.string().regex(urlRegex),
    stripeCancelUrl: Joi.string().regex(urlRegex)
  },
  bonus : {
    name: Joi.string()
      .max(100)
      .required(),
    conditions: Joi.when("verificationType", {
      is: "count",
      then: Joi.array().optional(),
      otherwise: Joi.array()
        .items(bonusConditionsSchema)
        .min(1)
        .required()
    }),
    type: Joi.string()
      .valid(["fixed", "percentage"])
      .required(),
    status: Joi.string()
      .valid(["active", "inactive"])
      .required(),
    value: Joi.number().required(),
    verificationType: Joi.string()
      .valid(["criteria", "count"])
      .required(),
    goal: Joi.when("verificationType", {
      is: "count",
      then: Joi.number().required(),
      otherwise: Joi.any().forbidden()
    }),
    includeIncomplete: Joi.boolean().required()
  },
  goals : {
    startDate: Joi.date().required(),
    endDate: Joi.date().required(),
    goal: Joi.number().required(),
    workdays: Joi.array().items(
      Joi.string()
    ).min(1),
    excludeDates: Joi.array()
      .items(Joi.date())
      .optional(),
    name: Joi.string()
      .max(100)
      .optional(),
    description: Joi.string()
      .max(255)
      .optional(),
    includeIncomplete: Joi.boolean().required()
  },
  createReport : {
    name: Joi.string()
      .max(100)
      .required(),
    description: Joi.string()
      .max(255),
    reportFolderId: Joi.string().uuid(),
    type: Joi.string()
      .valid("entries", "bonus", "goals", "times")
      .required()
  },
  createUser: {
    username: Joi.string()
      .email()
      .required(),
    firstName: Joi.string()
      .max(40)
      .required(),
    lastName: Joi.string()
      .max(80)
      .required(),
    email: Joi.string()
      .email()
      .required(),
    profile: Joi.any()
      .valid(["Recruiter", "Team Leader", "Backoffice", "Administrator"])
      .required(),
    sendEmailSetPassword: Joi.boolean(),
    active: Joi.boolean(),
    mobile: Joi.string()
      .max(40)
      .allow(""),
    street: Joi.string()
      .max(255)
      .allow(""),
    postalCode: Joi.string()
      .max(40)
      .allow(""),
    city: Joi.string()
      .max(100)
      .allow(""),
    country: Joi.string()
      .max(40)
      .allow(""),
    fiscalId: Joi.string()
      .max(40)
      .allow(""),
    iban : Joi.string()
      .max(34)
      .allow(""),
    userImage: Joi.string().regex(/^(data:image\/([a-zA-Z]*);base64,([^\"]*))|(https:\/\/storage.googleapis.com\/[\.0-9a-zA-Z\/-]*)$/)
  },
  userCredentials: {
    username: Joi.string().email().required(),
    password: Joi.string().required()
  },
  resetPassword : {
    username : Joi.string().email().required()
  },
  createPassword : {
    password: Joi.string()
      .regex(passwordRegex)
      .min(6)
      .required(),
    confirmPassword : Joi.string()
      .regex(passwordRegex)
      .min(6)
      .required()
      .valid(Joi.ref('password'))
  }
};

module.exports = {
  stripe: stripe => Joi.validate(stripe, schemas.stripe, {abortEarly : false}),
  goals: goal => Joi.validate(goal, schemas.goals, {abortEarly : false}),
  bonus: bonus => Joi.validate(bonus, schemas.bonus, {abortEarly : false}),
  createReport: report => Joi.validate(report, schemas.createReport, {abortEarly : false}),
  createUser: user => Joi.validate(user, schemas.createUser, {abortEarly : false}),
  userCredentials: userCredentials => Joi.validate(userCredentials, schemas.userCredentials, {abortEarly : false}),
  resetPassword: email => Joi.validate(email, schemas.resetPassword),
  createPassword: passwordData => Joi.validate(passwordData, schemas.createPassword, {abortEarly : false})
};