import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
const labels = require("../../labels");

export default class DatePickerCustom extends Component {
  constructor(props) {
    super(props);
    const { value, minYear, maxYear, minDate, maxDate } = props;

    let dateToday = new Date();
    let dateValues = value && value.split("-");

    let month = (dateValues && parseInt(dateValues[1], 10));
    if(month > 0) month = month -1;

    this.state = {
      day: (dateValues && parseInt(dateValues[2], 10)) || "",
      month:  month >= 0 ? month : "",
      year: (dateValues && parseInt(dateValues[0], 10)) || "",
      maxYear: (maxDate && parseInt(maxDate.split("-")[0], 10)) || maxYear || dateToday.getFullYear(),
      minYear: (minDate && parseInt(minDate.split("-")[0], 10)) || minYear || dateToday.getFullYear() - 100
    };
  }

  getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1);
    let days = 0;

    if (!month || month === "") {
      return 31;
    }
    while (date.getMonth() === month) {
      days++;
      date.setDate(date.getDate() + 1);
    }

    return days;
  };

  getNumberOptions = (min, max, reverseOrder) => {
    let components = [];
    let startAtZero = min === 0;

    if (reverseOrder)
      for (let i = max; i > min; i--) {
        components.push(
            <MenuItem key={i} value={i} >{(startAtZero && i + 1) || i}</MenuItem>
        );
      }
    else {
      for (let i = min; i <= max; i++) {
        components.push(
            <MenuItem key={i} value={i}>{(startAtZero && i + 1) || i}</MenuItem>
        );
      }
    }

    return components;
  };

  callback = val => {
    const {name, required, validations, onChange, onBlur} = this.props;

    onChange && onChange(name, val);

    onBlur && onBlur(name, val, required, validations)
  };

  handleChange = (val, field) => {
    let intVal = parseInt(val, 10);
    let stateCopy = { ...this.state, [field]: intVal };
    this.setState(stateCopy);

    if (field !== "day") {
      let { day, month, year } = stateCopy;
      let maxDays = this.getDaysInMonth(month, year);
      stateCopy = { ...stateCopy, day: (day > maxDays && maxDays) || day };
      this.setState(stateCopy);
    }
    if (
      stateCopy.day != null &&
      stateCopy.day !== "" &&
      stateCopy.month != null &&
      stateCopy.month !== "" &&
      stateCopy.year != null &&
      stateCopy.year !== ""
    ) {
      const { year, month, day } = stateCopy;
      this.callback(
        `${year}-${month != null && month !== "" && (month > 8) ? month + 1 : "0"+(month+1)}-${day != null && day !== "" && (day > 9) ? day : "0"+(day)}`
      );
    }
  };


  render() {
    const { day, month, year, minYear, maxYear } = this.state;
    const { label, required, value, className, hasError, errorMessage, readOnly, language } = this.props;
    
    return (
      <FormControl 
        className="date-selector" 
        error={hasError}
        variant="outlined"  
      >
        <InputLabel shrink={true} className={value ? "filled" : ""}>
          {label + (required ? " *":"")}
        </InputLabel>

        <TextField
          id="day"
          error={hasError}
          fullWidth={true}
          className={className + ' day'}
          select
          value={day}
          onChange={(event) => this.handleChange(event.target.value, "day")}
          variant="outlined"
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            readOnly:readOnly
          }}
        >
          <MenuItem value="" disabled>
            {labels.getLabel("day", language)}
          </MenuItem>
          {this.getNumberOptions( 1, this.getDaysInMonth(month, year || maxYear))}
        </TextField>

        <TextField
          id="month"
          error={hasError}
          fullWidth={true}
          className={className + ' month'}
          select
          value={month}
          onChange={(event) => this.handleChange(event.target.value, "month")}
          variant="outlined"
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            readOnly:readOnly
          }}
        >
          <MenuItem value="" disabled>
            {labels.getLabel("Month", language)}
          </MenuItem>
          {this.getNumberOptions(0, 11)}
        </TextField>

        <TextField
          id="year"
          error={hasError}
          fullWidth={true}
          className={className + ' year'}
          select
          value={year}
          onChange={(event) => this.handleChange(event.target.value, "year")}
          variant="outlined"
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            readOnly:readOnly
          }}
        >
          <MenuItem value="" disabled>
            {labels.getLabel("year", language)}
          </MenuItem>
          {this.getNumberOptions(minYear, maxYear, true)}
        </TextField>
          
        {errorMessage &&
          <FormHelperText className="error">
            {errorMessage}
          </FormHelperText>
        }
      </FormControl>
    );
  }
}