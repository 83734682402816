import React, { Component } from 'react';
import Link from "react-router-dom/Link";
//import {Bar} from 'react-chartjs-2';
/*import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';*/
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormIcon from '@material-ui/icons/Favorite';
import NoteIcon from '@material-ui/icons/Note';
import ArrowPrevious from '@material-ui/icons/KeyboardArrowLeft';
import ArrowNext from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import CreateNoteModal from './Notes/CreateNoteModal';
import Gauge from './GaugeChart';

/*import {Responsive, WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
const ResponsiveReactGridLayout = WidthProvider(Responsive);*/
const utility_functions = require("../utility_functions.js");
const labels = require("../labels");
const moment = require('moment');
//const GaugeChart = require("@recogizer/gauge-chart");

class Homepage extends Component {
  state = {
    openNoteCreation : false
  }

  toggleNoteCreationModal = () => this.setState({ openNoteCreation: !this.state.openNoteCreation });

  render() {
    const { openNoteCreation } = this.state;
    const { 
      setSnackBar, 
      themeColors, 
      history, 
      userData, 
      checkLocalStorage,
      getLocalStorageEntries,
      setLocalStorageNoteEntry,
      online,
      /*times,
      setTimes,
      getTimes,*/
      language,
      logout
    } = this.props;

    return (
      <div className="wrapper">
        <div className="homepage">
          {/*<Dashboard />*/}
          <div className="dashboard">
            <Quote
              themeColors={themeColors}
              userData={userData}
              language={language}
              logout={logout}
              setSnackBar={setSnackBar}
            />
            {/*!userData.timerOptional && 
            <Time 
              times={times}
              getTimes={getTimes}
              setTimes={setTimes}
              language={language}
            />*/}
            {online === false
              ? <OfflineResults 
                  userData={userData}
                  language={language}
                  getLocalStorageEntries={getLocalStorageEntries}
                />
              : <div>
                  <Results 
                    userData={userData}
                    language={language}
                    logout={logout}
                    setSnackBar={setSnackBar}
                  />
                  {userData.profile !== "Recruiter" && userData.profile !== "Team Leader" &&
                    <CardRecentDonations 
                      history={history}
                      userData={userData}
                      language={language}
                      logout={logout}
                      setSnackBar={setSnackBar}
                    />
                  }
                </div>
            }
          </div>
        </div>

        <QuickActionsBar 
          themeColors={themeColors}  
          toggleNoteCreationModal={this.toggleNoteCreationModal}
          language={language}
        />

        <CreateNoteModal 
          handleClose={this.toggleNoteCreationModal}
          setLocalStorageNoteEntry={setLocalStorageNoteEntry}
          userTeamId={userData.teamId}
          open={openNoteCreation}
          setSnackBar={setSnackBar}
          checkLocalStorage={checkLocalStorage}
          logout={logout}
          online={online}
          language={language}
        />
      </div>
    );
  }
}

class Quote extends Component {
  state = {
    quote : ""
  }

  componentWillMount(){
    utility_functions.getOrganizationData()
    .then(res => {
      //console.log(res);
      if(res.data.status === "success"){
        const ongData = res.data.data;
        this.setState({ quote: ongData.dailyQuote });
      }
    })
    .catch(error => {
      const self = this;
      if(error.response && error.response.data.data 
        && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
        setTimeout(function(){ 
          self.props.logout();
        }, 3000);
        this.props.setSnackBar(
          labels.getLabel("doLoginAgain", this.props.language), 
          "warning"
        );
      }
    });
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ quote : value });
  }

  submitQuote = () => {
    const requestObj = { dailyQuote: this.state.quote };
    utility_functions.editOrganization(requestObj);
  }

  render(){
    const { themeColors, userData } = this.props;
    const { quote } = this.state;
    
    if(quote)
      return(
        <div className="card quote" style={{backgroundColor: themeColors.primary.main}}>
          <TextField
            id="quote"
            className="transparentInput"
            value={quote}
            margin="none"
            variant="filled"
            type="text"
            fullWidth
            multiline
            onBlur={this.submitQuote}
            onChange={this.handleChange}
            style={{
              color:"white"
            }}
            InputProps={{
              readOnly : userData.profile !== "Administrator",
              style: {
                color: themeColors.primary.contrastText
              },
              classes:{
                focused: "focused"
              }
            }}
          />
        </div>
    )
    return null;
  }
}

/*class Time extends Component {
  state = {
    times : []
  }

  componentWillMount(){ 
    this.props.getTimes();
    this.checkTimes(this.props.times);
  }
  
  componentWillReceiveProps(nextProps){
    const times = nextProps.times;
    this.checkTimes(times);
  }

  checkTimes = (times) => {
    if(times && times.length > 0){
      const auxTimes = times.map(t => {
        return {...t}
      });
      this.setTimes(auxTimes);
    }
  }

  componentWillUnmount(){
    clearInterval(this.timer);
    this.timer = undefined;
  }

  setTimes = times => {
    if(!times[0].endTime){
      if(!this.timer)
        this.timer = setInterval(() => {
          const difference = new Date() - new Date(times[0].startTime);
          const endDateInMilSec = new Date(times[0].startTime).setMilliseconds(difference);
          times[0].endTime = new Date(endDateInMilSec).getTime();
          this.setState({
            times: times
          });
        }, 1000);
    }else{
      clearInterval(this.timer);
      this.timer = undefined;
      this.setState({ 
        times: times
      });
    }
  }

  render(){
    const { times } = this.state;
    const { language } = this.props;

    return(
      <div className="card">
        <Typography variant="h6" className="title">
          {labels.getLabel("todayTimeEntries", language)}
        </Typography>
        <div className="time">
        <div className="total">
            <Typography
              dangerouslySetInnerHTML={{
                __html : utility_functions.getTotalTimeDifference(times, language)
              }}
            />
          </div>
          {times.map((t, index) => (
            <div className="line" key={JSON.stringify(t)}>
              <div className="time">
                <Typography id="hours">
                  {index === 0 && this.props.times && this.props.times.length > 0 && this.props.times[0].id === t.id && !this.props.times[0].endTime
                    ? utility_functions.getFormattedDate(t.startTime, 'onlyTime') +' - a decorrer'
                    : utility_functions.getFormattedDate(t.startTime, 'onlyTime') +' - '+ utility_functions.getFormattedDate(t.endTime, 'onlyTime')
                  }
                </Typography>
                <Typography 
                  id="total"
                  dangerouslySetInnerHTML={{
                    __html : utility_functions.getTimeDifference(t.startTime, t.endTime)
                  }} 
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}*/

class OfflineResults extends Component {
  state = {
    numCompleteFormEntries : 0,
    numIncompleteFormEntries : 0,
    numNoteEntries : 0
  }

  componentWillMount(){
    const { userData } = this.props;
    const localStorageEntries = this.props.getLocalStorageEntries(userData.orgId, userData.username);

    const formEntries = localStorageEntries.formEntries;
    console.log(formEntries)

    const noteEntries = localStorageEntries.noteEntries;
    let numNoteEntries = noteEntries.length;
    let numCompleteFormEntries = 0;
    let numIncompleteFormEntries = 0;
    Object.keys(formEntries).forEach(formId => {
      formEntries[formId].forEach(entry => {
        if(entry.status.toLowerCase() === 'complete') numCompleteFormEntries++;
        else if(entry.status.toLowerCase() === 'incomplete') numIncompleteFormEntries++;
      });
    });

    this.setState({
      numCompleteFormEntries : numCompleteFormEntries,
      numIncompleteFormEntries : numIncompleteFormEntries,
      numNoteEntries : numNoteEntries
    });
  }

  render(){
    const { numCompleteFormEntries, numIncompleteFormEntries, numNoteEntries } = this.state;
    const { language } = this.props;

    return(
      <div className="card results offline">
        <Typography variant="h6" className="title">
          {labels.getLabel("offlineRecords", language)}
        </Typography>
        <div>
          {labels.getLabel("Completes", language)}
          <Typography variant="h6">{numCompleteFormEntries}</Typography>
        </div>
        <div>
          {labels.getLabel("Incompletes", language)}
          <Typography variant="h6">{numIncompleteFormEntries}</Typography>
        </div>
        <div>
          {labels.getLabel("Notes", language)}
          <Typography variant="h6">{numNoteEntries}</Typography>
        </div>
      </div>
    )
  }
}

class Results extends Component {
  state = {
    individualValues : {
      today : {},
      week : {},
      month : {}
    },
    teamValues : {
      today : {},
      week : {},
      month : {}
    },
    bonusSum : {
      individualTodayBonus : 0,
      individualWeekBonus : 0,
      individualMonthBonus : 0,
      teamTodayBonus : 0,
      teamWeekBonus : 0,
      teamMonthBonus : 0
    },
    /*goals : {
      individualToday : 0,
      individualWeek : 0,
      individualMonth : 0,
      teamToday : 0,
      teamWeek : 0,
      teamMonth : 0
    }*/
  }

  colors = {
    red:'rgb(255,84,84)',
    orange:'rgb(239,214,19)',
    green:'rgb(61,204,91)'
  } 

  componentDidMount(){
    const { profile, username, teamId } = this.props.userData;
    this.getUserChartsData();    
    if(profile === "Backoffice" || profile === "Administrator"){
      this.getOrganizationChartsData();
    }else{
      this.getTeamChartsData();
    }
    this.getBonusList(profile, username, teamId);
  }

  getUserChartsData = () => {
    utility_functions.getUserGoalsChartData()
    .then(res => {
      this.setState({
        individualValues : {
          today : res.data.data.today,
          week : res.data.data.week,
          month : res.data.data.month,
        }
      });
    });
  }

  getOrganizationChartsData = () => {
    utility_functions.getOrganizationChartsData()
    .then(res => {
      this.setState({
        teamValues : {
          today : res.data.data.today,
          week : res.data.data.week,
          month : res.data.data.month,
        }
      });
    });
  }

  getTeamChartsData = () => {
    utility_functions.getTeamChartsData()
    .then(res => {
      this.setState({
        teamValues : {
          today : res.data.data.today,
          week : res.data.data.week,
          month : res.data.data.month,
        }
      });
    });
  }

  getBonusList = (profile, username, teamId) => {
    utility_functions.getBonusList()
    .then(res => {
      const bonusList = res.data.data;
      this.handleBonusData(bonusList, profile, username, teamId);
    })
    .catch(error => {
        const self = this;
        if(error.response && error.response.data.data 
          && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
          setTimeout(function(){ 
            self.props.logout();
          }, 3000);
          this.props.setSnackBar(
            labels.getLabel("doLoginAgain", this.props.language), 
            "warning"
          );
        }
    })   
  }

  handleBonusData = (bonusList, profile, username, teamId) => {
    let bonusSum = {
      individualTodayBonus : 0,
      individualWeekBonus : 0,
      individualMonthBonus : 0,
      teamTodayBonus : 0,
      teamWeekBonus : 0,
      teamMonthBonus : 0
    };

    bonusList.filter(b => b.recruiter.username === username).forEach(b => {
      const { createdDate, amount } = b;
      const momentDate = moment(new Date(createdDate));
      if(momentDate.isSame(new Date(), 'days'))
        bonusSum.individualTodayBonus += amount;
      if(momentDate.isSame(new Date(), 'week'))
        bonusSum.individualWeekBonus += amount;
      if(momentDate.isSame(new Date(), 'month'))
        bonusSum.individualMonthBonus += amount;
    });

    if(profile === "Backoffice" || profile === "Administrator"){
      bonusList.forEach(b => {
        const { createdDate, amount } = b;
        const momentDate = moment(new Date(createdDate));
        if(momentDate.isSame(new Date(), 'days'))
          bonusSum.teamTodayBonus += amount;
        if(momentDate.isSame(new Date(), 'week'))
          bonusSum.teamWeekBonus += amount;
        if(momentDate.isSame(new Date(), 'month'))
          bonusSum.teamMonthBonus += amount;
      });
    }else{
      bonusList.filter(b => b.team.teamId === teamId).forEach(b => {
        const { createdDate, amount } = b;
        const momentDate = moment(new Date(createdDate));
        if(momentDate.isSame(new Date(), 'days'))
          bonusSum.teamTodayBonus += amount;
        if(momentDate.isSame(new Date(), 'week'))
          bonusSum.teamWeekBonus += amount;
        if(momentDate.isSame(new Date(), 'month'))
          bonusSum.teamMonthBonus += amount;
      });
    }

    Object.keys(bonusSum).forEach(b => {
      if(bonusSum[b] > 0){
        bonusSum[b] = bonusSum[b].toFixed(2);
      }
    });

    this.setState({ bonusSum });
  }

  render(){
    const { language, userData } = this.props;
    const { individualValues, teamValues, bonusSum/*, goals*/ } = this.state;
    const profile = userData.profile;

    const todayLabel = labels.getLabel("TODAY", language);
    const weekLabel = labels.getLabel("Week", language);
    const monthLabel = labels.getLabel("Month", language);

    return(
      <div>
        <div className="card results">
          <Typography variant="h6" className="title">
            {labels.getLabel("yourResults", language)}
          </Typography>
          <Gauge
            label={todayLabel}
            min={0}
            max={individualValues.today.goal && individualValues.today.goal * 2}
            currentValue={individualValues.today.current}
            delimiters={[0,50]}
            width={200}
            bonus={bonusSum.individualTodayBonus}
          />
          <Gauge
            label={weekLabel}
            min={0}
            max={individualValues.week.goal && individualValues.week.goal * 2}
            currentValue={individualValues.week.current}
            delimiters={[0,50]}
            width={200}
            bonus={bonusSum.individualWeekBonus}
          />
          <Gauge
            label={monthLabel}
            min={0}
            max={individualValues.month.goal && individualValues.month.goal * 2}
            currentValue={individualValues.month.current}
            delimiters={[0,50]}
            width={200}
            bonus={bonusSum.individualMonthBonus}
          />
        </div>
        <div className="card results">
          <Typography variant="h6" className="title">
            {profile === "Backoffice" || profile === "Administrator"
              ? labels.getLabel("generalResults", language)
              : labels.getLabel("yourTeamResults", language)
            }
          </Typography>
          <Gauge
            label={todayLabel}
            min={0}
            max={teamValues.today.goal && teamValues.today.goal * 2}
            currentValue={teamValues.today.current}
            delimiters={[0,50]}
            width={200}
            bonus={bonusSum.teamTodayBonus}
          />
          <Gauge
            label={weekLabel}
            min={0}
            max={teamValues.week.goal && teamValues.week.goal * 2}
            currentValue={teamValues.week.current}
            delimiters={[0,50]}
            width={200}
            bonus={bonusSum.teamWeekBonus}
          />
          <Gauge
            label={monthLabel}
            min={0}
            max={teamValues.month.goal && teamValues.month.goal * 2}
            currentValue={teamValues.month.current}
            delimiters={[0,50]}
            width={200}
            bonus={bonusSum.teamMonthBonus}
          />
        </div>
      </div>
    )
  }
}

/*class Dashboard extends Component {
  state = {
    isEditActive : true
  }

  renderGridLayout(layout){
    console.log(layout)
    return layout.map(component => (
      <div 
        className="card"
        key={component.i}
      >
        {component.name}
      </div>
    ))
  }

  handleLayoutChange(newLayout){
    console.log(newLayout)
  }

  render(){
    const { isEditActive } = this.state;

    let gridLayout = [
      {i: 'a', x: 0, y: 0, w: 1, h: 2},
      {i: 'b', x: 1, y: 0, w: 3, h: 2},
      {i: 'c', x: 4, y: 0, w: 1, h: 2}
    ];

    return (
      <ResponsiveReactGridLayout 
        className="layout" 
        verticalCompact={false}
        isDraggable={isEditActive}
        isResizable={isEditActive}
        onLayoutChange={this.handleLayoutChange}
        layouts={gridLayout}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
      >
        {this.renderGridLayout(gridLayout)}
      </ResponsiveReactGridLayout>
    )
  }
}*/

class CardRecentDonations extends Component{
  state = {
    donationsList : [],
    filter : "all",
    orderBy : 'createdDate',
    order: 'desc',
    page : 0,
    rowsPerPage : 10,
    total : 0
  };

  componentWillMount(){
    const { page, rowsPerPage, dateFilter, orderBy, order } = this.state;
    this.getDonationsList(page, rowsPerPage, dateFilter, orderBy, order);
  }

  getDonationsList = (page, rowsPerPage, dateFilter, orderBy, order) => {
    let sort = "&sort=-createdDate";
    let filters = `&page[number]=${page+1}&page[size]=${rowsPerPage}&filter[archived][eq]=false`;
    if(orderBy && order){
      sort = `&sort=${(order === "desc") ? "-" : ""}`;
      if(orderBy === "team"){
        sort+="team.name"
      }else if(orderBy === "amount" || orderBy === "name"){
        sort+=`fields.${orderBy}`
      }else if(orderBy === "recruiter"){
        sort+="recruiter.firstName"
      }else if(orderBy === "donationDate"){
        sort+="collectionDate"
      }else{
        sort+=orderBy
      }
    }
    if(dateFilter && dateFilter !== "all"){
      filters += this.buildDateFilters(dateFilter);
    }
    utility_functions.getDonationsList(filters+=sort)
    .then(res => {
      const recordsCount = (res.meta && res.meta.count) || 0;
      let rows = res.data.map(f => {
        let row = utility_functions.getDonationEntryObj(f);
        row.donationDate = utility_functions.getFormattedDate(row.donationDate, true);
        row.createdDate = utility_functions.getFormattedDate(row.createdDate, true);
        row.team = JSON.stringify(row.team);
        row.recruiter = JSON.stringify(row.recruiter);
        return row;
      });
      this.setState({ 
        donationsList : rows,
        total : recordsCount
      });
    })
    .catch(error => {
      const self = this;
      if(error.response && error.response.data.data 
        && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
        setTimeout(function(){ 
          self.props.logout();
        }, 3000);
        this.props.setSnackBar(
          labels.getLabel("doLoginAgain", this.props.language), 
          "warning"
        );
      }
    });
  }

  buildDateFilters = (dateFilter) => {
    let filters = "";
    if(dateFilter === "today"){
      const today = moment().toDate();
      const todayDate = today.getFullYear()+"-"+(today.getMonth()+1)+"-"+today.getDate();
      filters += `&filter[createdDate][gte]=${todayDate} 00:00:00&filter[createdDate][lte]=${todayDate} 23:59:59`;
    }else if(dateFilter === "yesterday"){
      const yesterday = moment().subtract(1, "day").toDate();
      const yesterdayDate = yesterday.getFullYear()+"-"+(yesterday.getMonth()+1)+"-"+yesterday.getDate();
      filters += `&filter[createdDate][gte]=${yesterdayDate} 00:00:00&filter[createdDate][lte]=${yesterdayDate} 23:59:59`;
    }else if(dateFilter === "this_week"){
      const thisWeekStart = moment().startOf('week').toDate();
      const thisWeekEnd = moment().endOf('week').toDate();
      const thisWeekStartDate = thisWeekStart.getFullYear()+"-"+(thisWeekStart.getMonth()+1)+"-"+thisWeekStart.getDate();
      const thisWeekEndDate = thisWeekEnd.getFullYear()+"-"+(thisWeekEnd.getMonth()+1)+"-"+thisWeekEnd.getDate();
      filters += `&filter[createdDate][gte]=${thisWeekStartDate} 00:00:00&filter[createdDate][lte]=${thisWeekEndDate} 23:59:59`;
    }else if(dateFilter === "last_week"){
      const lastWeekStart = moment().subtract(1, "week").startOf('week').toDate();
      const lastWeekEnd = moment().subtract(1, "week").endOf('week').toDate();
      const lastWeekStartDate = lastWeekStart.getFullYear()+"-"+(lastWeekStart.getMonth()+1)+"-"+lastWeekStart.getDate();
      const lastWeekEndDate = lastWeekEnd.getFullYear()+"-"+(lastWeekEnd.getMonth()+1)+"-"+lastWeekEnd.getDate();
      filters += `&filter[createdDate][gte]=${lastWeekStartDate} 00:00:00&filter[createdDate][lte]=${lastWeekEndDate} 23:59:59`;
    }
    return filters;
  }

  createSortHandler = property => event => {
    const { page, rowsPerPage, filter } = this.state;
    const orderBy = property;
    const order = (this.state.orderBy === property && this.state.order === 'desc')
      ? 'asc'
      : 'desc';
    this.setState({ order, orderBy });
    this.getDonationsList(page, rowsPerPage, filter, orderBy, order);
  };
  
  handleChangePage = (event, page) => {
    const { rowsPerPage, filter, orderBy, order } = this.state;
    this.setState({ page });
    this.getDonationsList(page, rowsPerPage, filter, orderBy, order);
  };
  
  handleChangeRowsPerPage = event => {
    const { filter, page, orderBy, order } = this.state;
    const rowsPerPage = event.target.value;
    this.setState({ rowsPerPage });
    this.getDonationsList(page, rowsPerPage, filter, orderBy, order);
  };
  
  sortFunctions = {
    desc : function desc(a, b, orderBy) {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      if(!isNaN(a[orderBy]))
        aValue = parseFloat(a[orderBy],10);
      else if(moment(a[orderBy], "DD/MM/YYYY HH:mm").isValid())
        aValue = moment(a[orderBy],"DD/MM/YYYY HH:mm").toDate();

      if(!isNaN(b[orderBy]))
        bValue = parseFloat(b[orderBy],10);
      else if(moment(b[orderBy], "DD/MM/YYYY HH:mm").isValid())
        bValue = moment(b[orderBy],"DD/MM/YYYY HH:mm").toDate();

      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    },
    stableSort : function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
          const order = cmp(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    },
    getSorting : function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }
  }
  
  handleFilterChange = (e) => {
    const filter = e.target.value;
    const { page, rowsPerPage, orderBy, order } = this.state;
    this.setState({ filter });
    this.getDonationsList(page, rowsPerPage, filter, orderBy, order);
  }

  goToDonationPage = formEntry => {
    const newWindow = window.open(`/donor-profile?id=${formEntry.id}`, "_blank");
    newWindow.focus();
  }

  render() {
    const { donationsList, filter, orderBy, order, page, rowsPerPage, total } = this.state;
    const { language } = this.props;

    const tableRows = [
      {id:'name', label: labels.getLabel("donor", language,)},
      {id:'age', label: labels.getLabel("age", language,'Age')},
      {id:'status', label: labels.getLabel("status", language)},
      {id:'amount', label: labels.getLabel("Amount", language)},
      {id:'team', label: labels.getLabel("team", language)},
      {id:'recruiter', label: labels.getLabel("recruiter", language)},
      {id:'donationDate', label: labels.getLabel("entryDate", language)},
      {id:'createdDate', label: labels.getLabel("synchDate", language)}
    ];
    
    const possibleOrdenations = ["status", "donationDate", "createdDate"];

    return (
      <div className="card card-recentdonations">
        <Typography variant="h6" className="title">
          {labels.getLabel("recentDonations", language)}
        </Typography>
        <div className="filter">
          <TextField
            id="dateFilter"
            label={labels.getLabel("Filter", language)}
            name="dateFilter"
            select
            value={filter}
            onChange={this.handleFilterChange}
            variant="outlined"
            SelectProps={{
              displayEmpty: false,
            }}
          >
            <MenuItem value="all">
              {labels.getLabel("all", language)}
            </MenuItem>
            <MenuItem value="today">
              {labels.getLabel("TODAY", language)}
            </MenuItem>
            <MenuItem value="yesterday">
              {labels.getLabel("YESTERDAY", language)}
            </MenuItem>
            <MenuItem value="this_week">
              {labels.getLabel("THIS_WEEK", language)}
            </MenuItem>
            <MenuItem value="last_week">
              {labels.getLabel("PREVIOUS_WEEK", language)}
            </MenuItem>
          </TextField>
        </div>
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                {tableRows.map(row => {
                  return (
                      <TableCell 
                          key={row.id}
                          sortDirection={orderBy === row.id ? order : false}
                      > 
                        {possibleOrdenations.indexOf(row.id) !== -1
                        ? <TableSortLabel
                              active={orderBy === row.id}
                              direction={order}
                              onClick={this.createSortHandler(row.id)}
                          >
                              {row.label}
                          </TableSortLabel>
                        : row.label
                        }
                      </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {donationsList.map(formEntry => {
                  return (
                    <TableRow 
                      hover 
                      key={formEntry.id}
                      onClick={() => this.goToDonationPage(formEntry)}
                    >
                        <TableCell>
                          {formEntry.name}
                        </TableCell>
                        <TableCell>{formEntry.age}</TableCell>
                        <TableCell>
                          {labels.getLabel(formEntry.status, language, formEntry.status)}                      
                        </TableCell>
                        <TableCell>{formEntry.amount}€</TableCell>
                        <TableCell>{formEntry.team && JSON.parse(formEntry.team).teamName}</TableCell>
                        <TableCell>{formEntry.recruiter && JSON.parse(formEntry.recruiter).firstName+' '+JSON.parse(formEntry.recruiter).lastName}</TableCell>
                        <TableCell>{formEntry.donationDate}</TableCell>
                        <TableCell>{formEntry.createdDate}</TableCell>
                    </TableRow>    
                  )
              })}
            </TableBody>
          </Table>
        </div>

        <div className="table-pagination">
          <div className="table-pagination-wrapper">
            <div className="spacer" />
            <span className="text">
              {labels.getLabel("RowsPerPage", language)}
            </span>
            <div className="input">
              <Select
                className="select"
                id="rowsPerPage"
                select="true"
                value={rowsPerPage}
                onChange={this.handleChangeRowsPerPage}
              >
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
            </div>
            {total > 0 &&
              <span className="total">
                {`${page > 0 ? page*rowsPerPage : 1}-${(page+1)*rowsPerPage} ${labels.getLabel("from", language)} ${total}`}
              </span>
            }
            <div className="actions">
              <IconButton 
                aria-label="Previous Page" 
                disabled={page === 0}
                onClick={(event) => this.handleChangePage(event, page-1)}
              >
                <ArrowPrevious />
              </IconButton>
              <IconButton 
                aria-label="Next Page"
                disabled={donationsList.length === 0}
                onClick={(event) => this.handleChangePage(event, page+1)}
              >
                <ArrowNext />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class QuickActionsBar extends Component{
  render(){
    const { themeColors, toggleNoteCreationModal, language } = this.props;

    const barStyle = {
      backgroundColor: themeColors.primary.main,
      color: themeColors.primary.contrastText
    }

    const iconStyle = {
      //border: `2px solid ${themeColors.primary.contrastText}`,
      fill: themeColors.primary.contrastText
    }

    const margin = {
      margin : "0 3%"
    }

    return(
      <div className="quick-actions-bar" style={barStyle}>
        <Link 
          to="/form" 
          style={margin}
        >
          <ButtonBase className="action-item">
            <FormIcon 
              className="icon" 
              style={iconStyle} 
            />
            <div className="text">
              {labels.getLabel("Form", language)}
            </div>
          </ButtonBase>
        </Link>
        <ButtonBase 
          className="action-item" 
          onClick={toggleNoteCreationModal}
          style={margin}
        >
          <NoteIcon 
            className="icon" 
            style={iconStyle} 
          />
          <div className="text">
            {labels.getLabel("createNote", language)}
          </div>
        </ButtonBase>
      </div>
    )
  }
}

export default Homepage;