const labels = [
    {
        "id" : "avoidIncomplete",
        "pt" : "Sem recibo de donativo/dedução IRS",
        "en" : "I don't want to give my <{value}>",
        "fr" : "Je ne veux pas partager le mon <{value}>",
        "es" : "No quiero compartir mi <{value}>"
    },
    {
        "id" : "valueToPay",
        "pt": "Valor a pagar",
        "en": "Amount to pay",
        "fr": "Montant à payer",
        "es": "Valor a pagar"
    },{
        "id" : "archived",
        "pt": "Arquivado",
        "en": "Arquived",
        "fr": "classé",
        "es": "archivado"
    },{
        "id" : "dateGroupBy",
        "pt": "Agrupar Data por",
        "en": "Group date by",
        "fr": "Date groupe par",
        "es": "Agrupar fecha por"
    },{
        "id" : "avg",
        "pt": "Média",
        "en": "Average",
        "fr": "Moyenne",
        "es": "Media"
    },
    {
        "id" : "Summarize",
        "pt": "Resumir",
        "en": "Summarize",
        "fr": "Résumer",
        "es": "Resumir"
    },
    {
        "id" : "Age",
        "pt": "Idade",
        "en": "Age",
        "fr": "Âge",
        "es": "Edad"
    },
    {
        "id": "editIndividualBonus",
        "pt": "Editar bonus individual",
        "en": "Edit individual bonus",
        "fr": "Modifier le bonus individuel",
        "es": "Editar bonificación individual"
    },{
        "id": "cloneReport",
        "pt": "Clonar report",
        "en": "Clone report",
        "fr": "Rapport de clonage",
        "es": "Informe de clonación"
    },{
        "id": "Clone",
        "pt": "Clonar",
        "en": "Clone",
        "fr": "Clone",
        "es": "Clon"
    },
    {
        "id": "showObjectiveLine",
        "pt": "Mostrar linha de objetivo",
        "en": "Show goal line",
        "fr": "Montrer la ligne de but",
        "es": "Mostrar línea de gol"
    },
    {
        "id": "xAxes",
        "pt": "Eixo - X",
        "en": "X axis",
        "fr": "Axe des x",
        "es": "Eje x"
    },
    {
        "id": "yAxes",
        "pt": "Eixo - Y",
        "en": "Y axis",
        "fr": "Axe des y",
        "es": "Eje y"
    },
    {
        "id": "dividedBy",
        "pt": "Repartido por",
        "en": "Divided by",
        "fr": "Divisé par",
        "es": "Dividido por"
    },
    {
        "id": "Data",
        "pt": "Dados",
        "en": "Data",
        "fr": "Les données",
        "es": "Datos"
    },
    {
        "id": "Profile",
        "pt": "Perfil",
        "en": "Profile",
        "fr": "Profil",
        "es": "Perfil"
    },
    {
        "id": "themeColor",
        "pt": "Cor do tema",
        "en": "Theme Color",
        "fr": "Couleur du thème",
        "es": "Color del tema"
    },
    {
        "id": "View",
        "pt": "Vista",
        "en": "View",
        "fr": "Vue",
        "es": "Vista"
    },
    {
        "id": "stripe",
        "pt": "Stripe",
        "en": "Stripe",
        "fr": "Stripe",
        "es": "Stripe"
    },
    {
        "id": "string.regex.base",
        "pt": "O formato do campo é inválido",
        "en": "Field format is invalid",
        "fr": "Le format du champ est invalide",
        "es": "El formato del campo no es válido."
    },
    {
        "id": "stripeSecretKey",
        "pt": "Chave secreta",
        "en": "Secret key",
        "fr": "Chave secreta",
        "es": "Clave secreta"
    },
    {
        "id": "stripePublishableKey",
        "pt": "Chave publicavel",
        "en": "Publishable Key",
        "fr": "Clé Publiable",
        "es": "Clave publicable"
    },
    {
        "id": "stripeSuccessUrl",
        "pt": "URL de sucesso",
        "en": "Success URL",
        "fr": "URL de réussite",
        "es": "URL de éxito"
    },
    {
        "id": "stripeCancelUrl",
        "pt": "URL de cancelamento",
        "en": "Cancellation URL",
        "fr": "URL d'annulation",
        "es": "URL de cancelación"
    },
    {
        "id": "stripePaymentCancel",
        "pt": "O pagamento foi cancelado",
        "en": "Payment has been canceled",
        "fr": "Le paiement a été annulé",
        "es": "Pago ha sido cancelado"
    },
    {
        "id": "sum",
        "pt": "Soma",
        "en": "Sum",
        "fr": "Somme",
        "es": "Suma"
    },
    {
        "id": "bonusId",
        "pt": "Id da Regra",
        "en": "Rule Id",
        "fr": "ID de règle",
        "es": "Id. De regla"
    },
    {
        "id": "Order",
        "pt": "Encomendar",
        "en": "Order",
        "fr": "Ordre",
        "es": "Orden"
    },
    {
        "id": "buySubscriptions",
        "pt": "Comprar Subscrições",
        "en": "Buy Subscriptions",
        "fr": "Acheter des abonnements",
        "es": "Comprar Suscripciones"
    },
    {
        "id": "Invoice",
        "pt": "Recibo",
        "en": "Receipt",
        "fr": "Le reçu",
        "es": "Recibo"
    },
    {
        "id": "numberSubscriptions",
        "pt": "Nº Subscrições",
        "en": "Nº Subscriptions",
        "fr": "Nº d'abonnements",
        "es": "Nº Suscripciones"
    },
    {
        "id": "timesCreatedSuccessfully",
        "pt": "Tempos criados com sucesso",
        "en": "Successfully created times",
        "fr": "Fois créé avec succès",
        "es": "Tiempos exitosamente creados"
    },
    {
        "id": "downloadLocalStorage",
        "pt": "Descarregar localStorage",
        "en": "Download localStorage",
        "fr": "Télécharger localStorage",
        "es": "Descargar localStorage"
    },
    {
        "id": "goalDuration",
        "pt": "Duração do objetivo",
        "en": "Duration of goal",
        "fr": "Durée de l'objectif",
        "es": "Duración del objetivo"
    },
    {
        "id": "defineGoal",
        "pt": "Cria um objetivo definindo as questões seguintes",
        "en": "Create a goal by defining the following issues",
        "fr": "Créez un objectif en définissant les problèmes suivants",
        "es": "Crea un objetivo definiendo los siguientes problemas"
    },
    {
        "id": "expectedDailyRecords",
        "pt": "Número de registos diários esperados *",
        "en": "Number of expected daily records *",
        "fr": "Nombre d'enregistrements quotidiens attendus *",
        "es": "Número de registros diarios esperados *"
    },
    {
        "id": "workDays",
        "pt": "Dias de trabalho semanais",
        "en": "Weekly workdays",
        "fr": "Jours de travail hebdomadaires",
        "es": "Días laborables semanales"
    },
    {
        "id": "daysToIgnore",
        "pt": "Dias a ignorar (férias, feriados e outros)",
        "en": "Days to ignore (holidays and others)",
        "fr": "Jours à ignorer (vacances et autres)",
        "es": "Días a ignorar (vacaciones y otros)"
    },
    {
        "id": "Continue",
        "pt": "Continuar",
        "en": "Continue",
        "fr": "Continuer",
        "es": "Continuar"
    },
    {
        "id": "selectADate",
        "pt": "Selecionar uma data",
        "en": "Select a date",
        "fr": "Sélectionnez une date",
        "es": "Seleccione una fecha"
    },
    {
        "id": "Add",
        "pt": "Adicionar",
        "en": "Add",
        "fr": "Ajouter",
        "es": "Añadir"
    },
    {
        "id": "Add...",
        "pt": "Adicionar ...",
        "en": "Add ...",
        "fr": "Ajouter ...",
        "es": "Añadir ..."
    },
    {
        "id": "newCondition",
        "pt": "Nova condição",
        "en": "New condition",
        "fr": "Nouvelle condition",
        "es": "Nueva condición"
    },
    {
        "id": "bonusType",
        "pt": "Tipo de bonus",
        "en": "Type of bonus",
        "fr": "Type de bonus",
        "es": "Tipo de bonificación"
    },
    {
        "id": "bonusValue",
        "pt": "Valor do bonus",
        "en": "Bonus amount",
        "fr": "Montant du bonus",
        "es": "Monto del bono"
    },{
        "id": "bonusPercentage",
        "pt": "Percentagem do bonus",
        "en": "Bonus percentage",
        "fr": "Pourcentage de bonus",
        "es": "Porcentaje de bonificación"
    },
    {
        "id": "bonusRule",
        "pt": "Regra de Bonus",
        "en": "Bonus Rule",
        "fr": "Règle de bonus",
        "es": "Regla de bonificación"
    },
    {
        "id": "defineBonusRules",
        "pt": "Defina as regras para atribuição de bonus através das questões seguintes",
        "en": "Define the rules for assigning bonuses through the following questions",
        "fr": "Définissez les règles d'attribution des bonus à l'aide des questions suivantes",
        "es": "Defina las reglas para asignar bonos a través de las siguientes preguntas"
    },
    {
        "id": "changeLocationSettings",
        "pt": "Mudar definições de localização",
        "en": "Change location settings",
        "fr": "Modifier les paramètres de localisation",
        "es": "Cambiar la configuración de ubicación"
    },
    {
        "id": "Both",
        "pt": "Ambos",
        "en": "Both",
        "fr": "Both",
        "es": "Both"
    },
    {
        "id": "availableCredits",
        "pt": "Disponíveis",
        "en": "Available",
        "fr": "Disponible",
        "es": "Disponible"
    },
    {
        "id": "ignoreDay",
        "pt": "Ignorar dia",
        "en": "Ignore day",
        "fr": "Ignorer le jour",
        "es": "Ignorar el día"
    },
    {
        "id": "deleteObjective",
        "pt": "Apagar dia",
        "en": "Delete day",
        "fr": "Supprimer le jour",
        "es": "Eliminar dia"
    },
    {
        "id": "expectedRecordsByUserPerDay",
        "pt": "Número de registos diários por recrutador",
        "en": "Number of daily records per recruiter",
        "fr": "Nombre d'enregistrements quotidiens par recruteur",
        "es": "Número de registros diarios por reclutador"
    },
    {
        "id": "editGeneralObjective",
        "pt": "Editar objetivo geral",
        "en": "Edit General Goal",
        "fr": "Modifier l'objectif général",
        "es": "Editar objetivo general"
    },
    {
        "id": "editObjective",
        "pt": "Editar Objetivo",
        "en": "Edit Goal",
        "fr": "Modifier l'objectif",
        "es": "Editar objetivo"
    },
    {
        "id": "thereAreRegistedTimesForUser",
        "pt": "Verificamos que existem tempos registados para os utilizadores selecionados neste período. O que devemos fazer?",
        "en": "We have verified that there are times recorded for the users selected in this period. What should we do?",
        "fr": "Nous avons vérifié qu'il y avait des heures enregistrées pour les utilisateurs sélectionnés pendant cette période. Que devrions nous faire?",
        "es": "Hemos verificado que hay tiempos registrados para los usuarios seleccionados en este período. ¿Qué debemos hacer?"
    },
    {
        "id": "deleteOldAndCreateNewTimes",
        "pt": "Apagar os tempos existentes e criar novos",
        "en": "Erase existing times and create new ones",
        "fr": "Effacer les temps existants et en créer de nouveaux",
        "es": "Borrar tiempos existentes y crear nuevos."
    },
    {
        "id": "ignoreAndReplaceNewTimes",
        "pt": "Ignorar e sobrepor novos registos de tempo",
        "en": "Ignore and overwrite new timestamps",
        "fr": "Ignorer et écraser les nouveaux horodatages",
        "es": "Ignorar y sobrescribir nuevas marcas de tiempo"
    },
    {
        "id": "timeRange",
        "pt": "Selecionar dias para registar os horários do calendário",
        "en": "Select days to record calendar time",
        "fr": "Sélectionnez les jours pour enregistrer les heures du calendrier",
        "es": "Seleccione días para registrar tiempos de calendario"
    },
    {
        "id": "createTimes",
        "pt": "Criar tempos",
        "en": "Create times",
        "fr": "Créer des temps",
        "es": "Crear tiempos"
    },
    {
        "id": "teamsToInclude",
        "pt": "Equipas a incluir",
        "en": "Teams to include",
        "fr": "Equipes à inclure",
        "es": "Equipos a incluir"
    },
    {
        "id": "noAlocationsFound",
        "pt": "Não foram encontradas alocações para as datas definidas",
        "en": "No allocations were found for the dates defined",
        "fr": "Aucune allocation n'a été trouvée pour les dates définies",
        "es": "No se encontraron asignaciones para las fechas definidas."
    },
    {
        "id": "Operator",
        "pt": "Operador",
        "en": "Operator",
        "fr": "Opérateur",
        "es": "Operador"
    },
    {
        "id": "Variable",
        "pt": "Variável",
        "en": "Variable",
        "fr": "Variable",
        "es": "Variable"
    },
    {
        "id": "variableType",
        "pt": "Tipo de variável",
        "en": "Variable Type",
        "fr": "Type de variable",
        "es": "Tipo variable"
    },
    {
        "id": "createCondition",
        "pt": "Criar condição",
        "en": "Create condition",
        "fr": "Créer une condition",
        "es": "Crear condicion"
    },
    {
        "id": "noCredits",
        "pt": "Não existem créditos disponíveis. Contacte a organização.",
        "en": "No credits are available. Contact the organization.",
        "fr": "Aucun crédit n'est disponible. Contactez l'organisation.",
        "es": "No hay créditos disponibles. Póngase en contacto con la organización."
    },
    {
        "id": "Paid",
        "pt": "Pago",
        "en": "Paid out",
        "fr": "Payé",
        "es": "Pagado"
    },
    {
        "id": "NotPaid",
        "pt": "Não pago",
        "en": "Not Paid",
        "fr": "Impayé",
        "es": "No pagado"
    },
    {
        "id": "totalDonors",
        "pt": "Nº Doadores (total)",
        "en": "Nº Donors (Total)",
        "fr": "Nombre de donateurs (total)",
        "es": "Nº Donantes (Total)"
    },
    {
        "id": "dailyDonorAverage",
        "pt": "Nº Doadores (média)",
        "en": "Nº Donors (average)",
        "fr": "Nombre de donateurs (moyenne)",
        "es": "Nº de donantes (promedio)"
    },
    {
        "id": "totalGoalDiff",
        "pt": "Diferença (total)",
        "en": "Difference (total)",
        "fr": "Différence (totale)",
        "es": "Diferencia (total)"
    },
    {
        "id": "dailyAverageGoalDiff",
        "pt": "Diferença (média)",
        "en": "Difference (average)",
        "fr": "Différence (moyenne)",
        "es": "Diferencia (promedio)"
    },
    {
        "id": "totalObjective",
        "pt": "Objetivo (total)",
        "en": "Goal (total)",
        "fr": "But (total)",
        "es": "Objetivo (total)"
    },
    {
        "id": "averageObjective",
        "pt": "Objetivo (média)",
        "en": "Goal (average)",
        "fr": "But (moyenne)",
        "es": "Objetivo (promedio)"
    },
    {
        "id": "nDaysWorked",
        "pt": "Dias de trabalho",
        "en": "Workdays",
        "fr": "Jours ouvrables",
        "es": "Días laborables"
    },
    {
        "id": "doLoginAgain",
        "pt": "Por favor faça login novamente",
        "en": "Please sign in again",
        "fr": "Veuillez vous reconnecter",
        "es": "Por favor vuelva a iniciar sesión"
    },
    {
        "id": "reportConditionTypeCriteria",
        "pt": "Critério do registo",
        "en": "Record Criteria",
        "fr": "Critères d'enregistrement",
        "es": "Criterios de registro"
    },
    {
        "id": "reportConditionTypeCount",
        "pt": "Nº registos diários",
        "en": "Number of daily records",
        "fr": "Nombre de fiches journalières",
        "es": "Número de registros diarios"
    },
    {
        "id": "reportTypeEntries",
        "pt": "Registos",
        "en": "Records",
        "fr": "Records",
        "es": "Archivos"
    },
    {
        "id": "reportTypeBonus",
        "pt": "Bonus",
        "en": "Bonus",
        "fr": "Prime",
        "es": "Prima"
    },
    {
        "id": "reportTypeGoals",
        "pt": "Objetivos",
        "en": "Goals",
        "fr": "Buts",
        "es": "Metas"
    },
    {
        "id": "reportTypeTimes",
        "pt": "User / Tempos",
        "en": "User / Times",
        "fr": "Utilisateur / Temps",
        "es": "Usuario / Tiempos"
    },
    {
        "id": "includeIncomplete",
        "pt": "Incluir Incompletos",
        "en": "Include Incomplete",
        "fr": "Inclure incomplet",
        "es": "Incluir incompleto"
    },
    {
        "id": "Conditions",
        "pt": "Condições",
        "en": "Conditions",
        "fr": "Conditions",
        "es": "Condiciones"
    },
    {
        "id": "bonusFixedType",
        "pt": "Valor fixo",
        "en": "Fixed value",
        "fr": "Valeur fixe",
        "es": "Valor fijo"
    },
    {
        "id": "bonusPercentageType",
        "pt": "Percentagem do montante submetido",
        "en": "Percentage of the amount submitted",
        "fr": "Pourcentage du montant soumis",
        "es": "Porcentaje de la cantidad presentada."
    },
    {
        "id": "avgEntries",
        "pt": "Nº Registos (média/hora)",
        "en": "Nº of Records (average/hour)",
        "fr": "Nº d'enregistrements (moyenne / heure)",
        "es": "Nº de registros (promedio / hora)"
    },
    {
        "id": "totalEntries",
        "pt": "Nº Registos (total)",
        "en": "Nº of Records (total)",
        "fr": "Nº de records (total)",
        "es": "Nº de Registros (total)"
    },
    {
        "id": "avgValue",
        "pt": "Valor (média/hora)",
        "en": "Amount (average/hour)",
        "fr": "Montant (moyenne / heure)",
        "es": "Cantidad (promedio / hora)"
    },
    {
        "id": "totalValue",
        "pt": "Valor (total)",
        "en": "Amount (total)",
        "fr": "Montant total",
        "es": "Monto total)"
    },
    {
        "id": "totalHours",
        "pt": "Total de horas ",
        "en": "Hours (Total)",
        "fr": "Heures (Total)",
        "es": "Horas (Total)"
    },
    {
        "id": "form_submission_loginNeeded",
        "pt": "Por favor faça login quando tiver acesso à Internet. Entretanto, poderá continuar a submeter registos no formulário.",
        "en": "Please log in when you have access to the Internet. However, you can continue to submit forms.",
        "fr": "Veuillez vous connecter lorsque vous avez accès à Internet. Cependant, vous pouvez continuer à soumettre des formulaires.",
        "es": "Inicie sesión cuando tenga acceso a Internet. Sin embargo, puede continuar enviando formularios."
    },
    {
        "id": "Goal",
        "pt": "Objetivo",
        "en": "Goal",
        "fr": "But",
        "es": "Meta"
    },
    {
        "id": "Goals",
        "pt": "Objetivos",
        "en": "Goals",
        "fr": "Buts",
        "es": "Metas"
    },
    {
        "id": "Type",
        "pt": "Tipo",
        "en": "Type",
        "fr": "Type",
        "es": "Tipo"
    },
    {
        "id": "Value",
        "pt": "Valor",
        "en": "Value",
        "fr": "Valeur",
        "es": "Valor"
    },
    {
        "id": "recordId",
        "pt": "ID Registo",
        "en": "ID Record",
        "fr": "Enregistrement d'identification",
        "es": "Registro de identidad"
    },
    {
        "id": "reportsNoInformation",
        "pt": "Sem informação",
        "en": "No information",
        "fr": "Aucune information",
        "es": "Sin información"
    },
    {
        "id": "lastModifiedDate",
        "pt": "Data última modificação",
        "en": "Last modified Date",
        "fr": "Date de dernière modification",
        "es": "Fecha de última modificación"
    },
    {
        "id": "lastModifier",
        "pt": "Última modificação por",
        "en": "Last modified by",
        "fr": "Dernière modification par",
        "es": "Ultima modificacion por"
    },
    {
        "id": "lastModification",
        "pt": "Última modificação",
        "en": "Last modification",
        "fr": "dernière modification",
        "es": "última modificación"
    },
    {
        "id": "SystemInfo",
        "pt": "INFORMAÇÕES DO SISTEMA",
        "en": "SYSTEM INFORMATION",
        "fr": "INFORMATIONS SUR LE SYSTÈME",
        "es": "INFORMACIÓN DEL SISTEMA"
    },
    {
        "id": "Properties",
        "pt": "Propriedades",
        "en": "Features",
        "fr": "Caractéristiques",
        "es": "Caracteristicas"
    },
    {
        "id": "queued",
        "pt": "Em espera",
        "en": "On hold",
        "fr": "En attente",
        "es": "En espera"
    },
    {
        "id": "failed",
        "pt": "Falhou",
        "en": "Failed",
        "fr": "Échoué",
        "es": "Ha fallado"
    },
    {
        "id": "delivered",
        "pt": "Entregue",
        "en": "Delivered",
        "fr": "Livré",
        "es": "Entregado"
    },
    {
        "id": "clicked",
        "pt": "Clicou",
        "en": "Checked",
        "fr": "Vérifié",
        "es": "Comprobado"
    },
    {
        "id": "opened",
        "pt": "Aberto",
        "en": "Open",
        "fr": "Ouvrir",
        "es": "Abierto"
    },
    {
        "id": "rejected",
        "pt": "Rejeitado",
        "en": "Rejected",
        "fr": "Rejeté",
        "es": "Rechazado"
    },
    {
        "id": "accepted",
        "pt": "Aceite",
        "en": "Accept",
        "fr": "Acceptez",
        "es": "Aceptar"
    },
    {
        "id": "editSchedule",
        "pt": "Editar atribuição",
        "en": "Edit assignment",
        "fr": "Editer la mission",
        "es": "Editar tarea"
    },
    {
        "id": "newSchedule",
        "pt": "Nova atribuição",
        "en": "New assignment",
        "fr": "Nouvelle affectation",
        "es": "Nueva tarea"
    },
    {
        "id": "recruiterChangedSuccessfully",
        "pt": "Recrutador alterado com sucesso",
        "en": "Recruiter successfully changed",
        "fr": "Recruteur changé avec succès",
        "es": "Reclutador cambiado con éxito"
    },
    {
        "id": "emailStatus",
        "pt": "Email de confirmação",
        "en": "Confirmation email",
        "fr": "Email de confirmation",
        "es": "Email de confirmación"
    },
    {
        "id": "Date",
        "pt": "Data",
        "en": "Date",
        "fr": "Date",
        "es": "Fecha"
    },
    {
        "id": "Amount",
        "pt": "Valor",
        "en": "Amount",
        "fr": "Montant",
        "es": "Cantidad"
    },
    {
        "id": "emailSenderAddress",
        "pt": "Remetente de e-mail",
        "en": "Email sender",
        "fr": "Email de l'expéditeur",
        "es": "Remitente de correo electrónico"
    },
    {
        "id": "addGroup",
        "pt": "Adicionar grupo...",
        "en": "Add group ...",
        "fr": "Ajouter un groupe ...",
        "es": "Añadir grupo ..."
    },
    {
        "id": "Groups",
        "pt": "Grupos",
        "en": "Groups",
        "fr": "Groupes",
        "es": "Los grupos"
    },
    {
        "id": "calendar_Sun",
        "pt": "Dom",
        "en": "Sun",
        "fr": "Soleil",
        "es": "Dom"
    },
    {
        "id": "calendar_Mon",
        "pt": "Seg",
        "en": "Mon",
        "fr": "lun",
        "es": "Lun"
    },
    {
        "id": "calendar_Tue",
        "pt": "Ter",
        "en": "Tue",
        "fr": "Mar",
        "es": "mar"
    },
    {
        "id": "calendar_Wed",
        "pt": "Qua",
        "en": "Wed",
        "fr": "mer",
        "es": "Mie"
    },
    {
        "id": "calendar_Thu",
        "pt": "Qui",
        "en": "Thu",
        "fr": "jeu",
        "es": "Jue"
    },
    {
        "id": "calendar_Fri",
        "pt": "Sex",
        "en": "Fri",
        "fr": "ven",
        "es": "Vie"
    },
    {
        "id": "calendar_Sat",
        "pt": "Sáb",
        "en": "Sat",
        "fr": "Sam",
        "es": "Sab"
    },
    {
        "id": "January",
        "pt": "Janeiro",
        "en": "January",
        "fr": "janvier",
        "es": "enero"
    },
    {
        "id": "February",
        "pt": "Fevereiro",
        "en": "February",
        "fr": "février",
        "es": "febrero"
    },
    {
        "id": "March",
        "pt": "Março",
        "en": "March",
        "fr": "Mars",
        "es": "marzo"
    },
    {
        "id": "April",
        "pt": "Abril",
        "en": "April",
        "fr": "avril",
        "es": "abril"
    },
    {
        "id": "May",
        "pt": "Maio",
        "en": "May",
        "fr": "Peut",
        "es": "Mayo"
    },
    {
        "id": "June",
        "pt": "Junho",
        "en": "June",
        "fr": "juin",
        "es": "junio"
    },
    {
        "id": "July",
        "pt": "Julho",
        "en": "July",
        "fr": "juillet",
        "es": "julio"
    },
    {
        "id": "August",
        "pt": "Agosto",
        "en": "August",
        "fr": "août",
        "es": "agosto"
    },
    {
        "id": "September",
        "pt": "Setembro",
        "en": "September",
        "fr": "septembre",
        "es": "septiembre"
    },
    {
        "id": "October",
        "pt": "Outubro",
        "en": "October",
        "fr": "octobre",
        "es": "octubre"
    },
    {
        "id": "November",
        "pt": "Novembro",
        "en": "November",
        "fr": "novembre",
        "es": "noviembre"
    },
    {
        "id": "December",
        "pt": "Dezembro",
        "en": "December",
        "fr": "décembre",
        "es": "diciembre"
    },
    {
        "id": "Filter",
        "pt": "Filtrar",
        "en": "Filter",
        "fr": "Filtre",
        "es": "Filtrar"
    },
    {
        "id": "Editor",
        "pt": "Editor",
        "en": "Editor",
        "fr": "Éditeur",
        "es": "Editor"
    },
    {
        "id": "deselect",
        "pt": "Desselecionar",
        "en": "Deselect",
        "fr": "Désélectionner",
        "es": "Deseleccionar"
    },
    {
        "id": "selectAll",
        "pt": "Selecionar Todos",
        "en": "Select all",
        "fr": "Tout sélectionner",
        "es": "Seleccionar todo"
    },
    {
        "id": "editColor",
        "pt": "Editar Cor",
        "en": "Edit Color",
        "fr": "Modifier la couleur",
        "es": "Editar color"
    },
    {
        "id": "Add",
        "pt": "Adicionar",
        "en": "Add",
        "fr": "Ajouter",
        "es": "Añadir"
    },
    {
        "id": "addTeam",
        "pt": "Adicionar uma equipa",
        "en": "Add a team",
        "fr": "Ajouter une équipe",
        "es": "Añadir un equipo"
    },
    {
        "id": "onlyOnline",
        "pt": "Apenas disponível online",
        "en": "Only available online",
        "fr": "Seulement disponible en ligne",
        "es": "Solo disponible en linea"
    },
    {
        "id": "personalData",
        "pt": "Dados Pessoais",
        "en": "Personal Data",
        "fr": "Données personnelles",
        "es": "Información personal"
    },
    {
        "id": "logout",
        "pt": "Logout",
        "en": "Logout",
        "fr": "Connectez - Out",
        "es": "Cerrar sesión"
    },
    {
        "id": "Home",
        "pt": "Início",
        "en": "Start",
        "fr": "Début",
        "es": "comienzo"
    },
    {
        "id": "Calendar",
        "pt": "Calendário",
        "en": "Calendar",
        "fr": "Calendrier",
        "es": "Calendario"
    },
    {
        "id": "Teams",
        "pt": "Equipas",
        "en": "Teams",
        "fr": "Les équipes",
        "es": "Equipos"
    },
    {
        "id": "Reports",
        "pt": "Relatórios",
        "en": "Reports",
        "fr": "Rapports",
        "es": "Informes"
    },
    {
        "id": "Times",
        "pt": "Tempos",
        "en": "Times",
        "fr": "Fois",
        "es": "Veces"
    },
    {
        "id": "offlineMode",
        "pt": "Modo offline",
        "en": "Offline mode",
        "fr": "Mode hors-ligne",
        "es": "Modo offline"
    },
    {
        "id": "Setup",
        "pt": "Configuração",
        "en": "Setup",
        "fr": "Installer",
        "es": "Preparar"
    },
    {
        "id": "createdDate",
        "pt": "Data criação",
        "en": "Creation Date",
        "fr": "Date de création",
        "es": "Fecha de creación"
    },
    {
        "id": "entryDate",
        "pt": "Data registo",
        "en": "Record date",
        "fr": "Date d'enregistrement",
        "es": "Grabar fecha"
    },
    {
        "id": "synchDate",
        "pt": "Data sincronização",
        "en": "Synchronization Date",
        "fr": "Date de synchronisation",
        "es": "Fecha de sincronizacion"
    },
    {
        "id": "Reference",
        "pt": "Referência",
        "en": "Reference",
        "fr": "Référence",
        "es": "Referencia"
    },
    {
        "id": "sureToDeleteNote?",
        "pt": "Tem a certeza que pretende apagar esta nota?",
        "en": "Are you sure you want to delete this note?",
        "fr": "Êtes-vous sûr de vouloir supprimer cette note?",
        "es": "¿Estás seguro que quieres eliminar esta nota?"
    },
    {
        "id": "sureToDeleteBonus?",
        "pt": "Tem a certeza que pretende apagar este bonus?",
        "en": "Are you sure you want to delete this bonus?",
        "fr": "Êtes-vous sûr de vouloir supprimer cette bonus?",
        "es": "¿Estás seguro que quieres eliminar esta bonificación?"
    },
    {
        "id": "sureToDeleteReport?",
        "pt": "Tem a certeza que pretende apagar este relatório?",
        "en": "Are you sure you want to delete this report?",
        "fr": "Êtes-vous sûr de vouloir supprimer ce rapport?",
        "es": "¿Está seguro de que desea eliminar este informe?"
    },
    {
        "id": "Form",
        "pt": "Formulário",
        "en": "Form",
        "fr": "Forme",
        "es": "Formar"
    },
    {
        "id": "recentDonations",
        "pt": "Doações recentes",
        "en": "Recent donations",
        "fr": "Dons récents",
        "es": "Donaciones recientes"
    },
    {
        "id": "generalResults",
        "pt": "Resultados gerais",
        "en": "General results",
        "fr": "Résultats généraux",
        "es": "Resultados generales"
    },
    {
        "id": "yourTeamResults",
        "pt": "Resultados da tua equipa",
        "en": "Your team results",
        "fr": "Les résultats de votre équipe",
        "es": "Los resultados de tu equipo"
    },
    {
        "id": "yourResults",
        "pt": "Os teus resultados",
        "en": "Your results",
        "fr": "Vos résultats",
        "es": "Tus resultados"
    },
    {
        "id": "offlineRecords",
        "pt": "Os teus registos offline",
        "en": "Your offline records",
        "fr": "Vos enregistrements hors ligne",
        "es": "Tus registros offline"
    },
    {
        "id": "todayTimeEntries",
        "pt": "Horas de trabalho (Hoje)",
        "en": "Working Hours (Today)",
        "fr": "Heures de travail (aujourd'hui)",
        "es": "Horas de trabajo (Hoy)"
    },
    {
        "id": "insertValidDate",
        "pt": "Introduza uma hora correta",
        "en": "Please enter a correct time",
        "fr": "S'il vous plaît entrer une heure correcte",
        "es": "Por favor ingrese una hora correcta"
    },
    {
        "id": "insertBiggerDateError",
        "pt": "Introduza uma hora superior à hora de começo",
        "en": "Enter a time higher than the start time",
        "fr": "Entrez une heure supérieure à l'heure de début",
        "es": "Introduzca una hora superior a la hora de inicio"
    },
    {
        "id": "archiveEntry",
        "pt": "Arquivar registo?",
        "en": "Archive record?",
        "fr": "Enregistrement d'archives?",
        "es": "Archivo de registro?"
    },
    {
        "id": "archiveTeam",
        "pt": "Arquivar equipa",
        "en": "Archive team",
        "fr": "Équipe d'archives",
        "es": "Equipo de archivo"
    },
    {
        "id": "Role",
        "pt": "Cargo",
        "en": "Office",
        "fr": "Bureau",
        "es": "Oficina"
    },
    {
        "id": "emailsSentSuccess",
        "pt": "Emails enviados com sucesso",
        "en": "Emails successfully sent",
        "fr": "Emails envoyés avec succès",
        "es": "Correos electrónicos enviados con éxito"
    },
    {
        "id": "selected",
        "pt": "selecionado",
        "en": "Selected",
        "fr": "Choisi",
        "es": "Seleccionado"
    },
    {
        "id": "autoSendConfirmationEmail",
        "pt": "Enviar e-mail confirmação automaticamente",
        "en": "Send confirmation email automatically",
        "fr": "Envoyer un email de confirmation automatiquement",
        "es": "Enviar correo electrónico de confirmación automáticamente"
    },
    {
        "id": "ongName",
        "pt": "Nome da Organização",
        "en": "Organization Name",
        "fr": "nom de l'organisation",
        "es": "Nombre de la Organización"
    },
    {
        "id": "uploadLogo",
        "pt": "Carregar logo",
        "en": "Upload logo",
        "fr": "Télécharger le logo",
        "es": "Subir logo"
    },
    {
        "id": "teamLeader",
        "pt": "Chefe de Equipa",
        "en": "Team leader",
        "fr": "Chef d'équipe",
        "es": "Capitan del equipo"
    },
    {
        "id": "recruiter",
        "pt": "Recrutador",
        "en": "Recruiter",
        "fr": "Recruteur",
        "es": "Reclutador"
    },
    {
        "id": "Backoffice",
        "pt": "Backoffice",
        "en": "Backoffice",
        "fr": "Backoffice",
        "es": "Backoffice"
    },
    {
        "id": "Administrator",
        "pt": "Administrador",
        "en": "Administrator",
        "fr": "Administrateur",
        "es": "Administrador"
    },
    {
        "id": "total",
        "pt": "Total",
        "en": "Total",
        "fr": "Total",
        "es": "Total"
    },
    {
        "id": "showTotal",
        "pt": "Mostrar total",
        "en": "Show total",
        "fr": "Afficher le total",
        "es": "Mostrar total"
    },
    {
        "id": "showValues",
        "pt": "Mostrar valores",
        "en": "Show values",
        "fr": "Afficher les valeurs",
        "es": "Mostrar valores"
    },
    {
        "id": "Attributes",
        "pt": "Atributos",
        "en": "Attributes",
        "fr": "Les attributs",
        "es": "Atributos"
    },
    {
        "id": "chartType",
        "pt": "Tipo de Gráfico",
        "en": "Chart Type",
        "fr": "Type de graphique",
        "es": "Tipo de gráfico"
    },
    {
        "id": "addFilter",
        "pt": "Adicionar filtro...",
        "en": "Add filter ...",
        "fr": "Ajouter un filtre ...",
        "es": "Añadir filtro ..."
    },
    {
        "id": "Filters",
        "pt": "Filtros",
        "en": "Filters",
        "fr": "Les filtres",
        "es": "Filtros"
    },
    {
        "id": "addColumn",
        "pt": "Adicionar coluna...",
        "en": "Add column ...",
        "fr": "Ajouter une colonne ...",
        "es": "Añadir columna ..."
    },
    {
        "id": "columns",
        "pt": "Colunas",
        "en": "Columns",
        "fr": "Les colonnes",
        "es": "Columnas"
    },
    {
        "id": "export",
        "pt": "Exportar",
        "en": "Export",
        "fr": "Exportation",
        "es": "Exportar"
    },
    {
        "id": "records",
        "pt": "registos",
        "en": "Records",
        "fr": "Records",
        "es": "Archivos"
    },
    {
        "id": "record",
        "pt": "registo",
        "en": "Record",
        "fr": "Record",
        "es": "Grabar"
    },
    {
        "id": "Apply",
        "pt": "Aplicar",
        "en": "To apply",
        "fr": "Appliquer",
        "es": "Aplicar"
    },
    {
        "id": "Interval",
        "pt": "Intervalo",
        "en": "Interval",
        "fr": "Intervalle",
        "es": "Intervalo"
    },
    {
        "id": "editFilter",
        "pt": "Editar Filtro",
        "en": "Edit Filter",
        "fr": "Modifier le filtre",
        "es": "Editar filtro"
    },
    {
        "id": "Description",
        "pt": "Descrição",
        "en": "Description",
        "fr": "La description",
        "es": "Descripción"
    },
    {
        "id": "createReport",
        "pt": "Criar Relatório",
        "en": "Create a Report",
        "fr": "Créer un rapport",
        "es": "Crear un informe"
    },
    {
        "id": "editReport",
        "pt": "Editar Relatório",
        "en": "Edit Report",
        "fr": "Modifier le rapport",
        "es": "Editar informe"
    },
    {
        "id": "Folder",
        "pt": "Pasta",
        "en": "Folder",
        "fr": "Dossier",
        "es": "Carpeta"
    },
    {
        "id": "renameFolder",
        "pt": "Renomear Pasta",
        "en": "Rename Folder",
        "fr": "Renommer le dossier",
        "es": "Renombrar carpeta"
    },
    {
        "id": "deleteFolder",
        "pt": "Apagar Pasta",
        "en": "Delete Folder",
        "fr": "Supprimer le dossier",
        "es": "Eliminar carpeta"
    },
    {
        "id": "createFolder",
        "pt": "Criar Pasta",
        "en": "Create folder",
        "fr": "Créer le dossier",
        "es": "Crear carpeta"
    },
    {
        "id": "Name",
        "pt": "Nome",
        "en": "Name",
        "fr": "prénom",
        "es": "Nombre"
    },
    {
        "id": "Search",
        "pt": "Pesquisar...",
        "en": "Search...",
        "fr": "Chercher...",
        "es": "Buscar..."
    },
    {
        "id": "Subject",
        "pt": "Assunto",
        "en": "Subject",
        "fr": "Assujettir",
        "es": "Tema"
    },
    {
        "id": "Note",
        "pt": "Nota",
        "en": "Note",
        "fr": "Remarque",
        "es": "Nota"
    },
    {
        "id": "Notes",
        "pt": "Notas",
        "en": "Notes",
        "fr": "Remarques",
        "es": "Notas"
    },
    {
        "id": "createNote",
        "pt": "Criar Nota",
        "en": "Create Note",
        "fr": "Créer une note",
        "es": "Crear nota"
    },
    {
        "id": "insertNoteAnswer",
        "pt": "Insira uma resposta para esta nota",
        "en": "Enter a response for this note",
        "fr": "Entrez une réponse pour cette note",
        "es": "Ingrese una respuesta para esta nota"
    },
    {
        "id": "answer",
        "pt": "Responder",
        "en": "Reply",
        "fr": "Répondre",
        "es": "Respuesta"
    },
    {
        "id": "clearSignature",
        "pt": "Apagar assinatura",
        "en": "Erase signature",
        "fr": "Effacer la signature",
        "es": "Borrar firma"
    },
    {
        "id": "day",
        "pt": "Dia",
        "en": "Day",
        "fr": "journée",
        "es": "Día"
    },
    {
        "id": "year",
        "pt": "Ano",
        "en": "Year",
        "fr": "Année",
        "es": "Año"
    },
    {
        "id": "readAndAccept",
        "pt": "Li e aceito",
        "en": "I've read and accepted",
        "fr": "J'ai lu et accepté",
        "es": "He leído y aceptado"
    },
    {
        "id": "see",
        "pt": "Ver",
        "en": "Read",
        "fr": "Lis",
        "es": "Leer"
    },
    {
        "id": "delete",
        "pt": "Apagar",
        "en": "Delete",
        "fr": "Effacer",
        "es": "Borrar"
    },
    {
        "id": "delete?",
        "pt": "Apagar?",
        "en": "Delete?",
        "fr": "Effacer?",
        "es": "¿Borrar?"
    },
    {
        "id": "deleteReport",
        "pt": "Apagar Relatório",
        "en": "Delete Report",
        "fr": "Supprimer le rapport",
        "es": "Eliminar informe"
    },
    {
        "id": "edit",
        "pt": "Editar",
        "en": "Edit",
        "fr": "modifier",
        "es": "Editar"
    },
    {
        "id": "location",
        "pt": "Localização",
        "en": "Location",
        "fr": "Emplacement",
        "es": "Ubicación"
    },
    {
        "id": "team",
        "pt": "Equipa",
        "en": "Team",
        "fr": "Équipe",
        "es": "Equipo"
    },
    {
        "id": "sureToArchiveEntry?",
        "pt": "Tem a certeza que pretende arquivar este registo?",
        "en": "Are you sure you want to archive this record?",
        "fr": "Êtes-vous sûr de vouloir archiver cet enregistrement?",
        "es": "¿Estás seguro de que quieres archivar este registro?"
    },
    {
        "id": "sureToContinue?",
        "pt": "Tem a certeza que pretende continuar?",
        "en": "Are you sure you want to continue?",
        "fr": "Es-tu sur de vouloir continuer?",
        "es": "Estás seguro de que quieres continuar?"
    },
    {
        "id": "formWillBeIncomplete",
        "pt": "Ao continuar o registo, este formulário será submetido como <strong>Incompleto</strong> faltando <strong>NIF</strong> e/ou <strong>IBAN</strong>.",
        "en": "By continuing, this form will be submitted <strong> Incomplete </ strong>, missing <strong> NIF </ strong> and / or <strong> IBAN </ strong>.",
        "fr": "En continuant, ce formulaire sera envoyé <strong> incomplet </ strong>, manquant <strong> NIF </ strong> et / ou <strong> IBAN </ strong>.",
        "es": "Al continuar, este formulario se enviará <strong> Incompleto </ strong>, falta <strong> NIF </ strong> y / o <strong> IBAN </ strong>."
    },
    {
        "id": "thereAreEmptyFields",
        "pt": "Alguns campos não obrigatórios estão vazios, pretende continuar?",
        "en": "Some non-mandatory fields were not filled, do you want to continue?",
        "fr": "Certains champs non obligatoires sont vides, souhaitez-vous continuer?",
        "es": "Algunos campos no obligatorios están vacíos, ¿le gustaría continuar?"
    },
    {
        "id": "monthlyDonation",
        "pt": "O SEU DONATIVO MENSAL:",
        "en": "YOUR MONTHLY DONATION:",
        "fr": "VOTRE DON MENSUEL:",
        "es": "SU DONACIÓN MENSUAL:"
    },
    {
        "id": "fieldChanged",
        "pt": "O campo foi alterado",
        "en": "Field changed",
        "fr": "Champ changé",
        "es": "Campo cambiado"
    },
    {
        "id": "from",
        "pt": "de",
        "en": "from",
        "fr": "de",
        "es": "desde"
    },
    {
        "id": "to",
        "pt": "para",
        "en": "for",
        "fr": "pour",
        "es": "para"
    },
    {
        "id": "From",
        "pt": "De",
        "en": "From",
        "fr": "De",
        "es": "Desde"
    },
    {
        "id": "Until",
        "pt": "Até",
        "en": "Until",
        "fr": "Jusqu'à ce que",
        "es": "Hasta"
    },
    {
        "id": "modification",
        "pt": "Alteração",
        "en": "Modification",
        "fr": "Modification",
        "es": "Modificación"
    },
    {
        "id": "dateAndTime",
        "pt": "Data e Hora",
        "en": "Date and time",
        "fr": "Date et l'heure",
        "es": "Fecha y hora"
    },
    {
        "id": "modifiedBy",
        "pt": "Modificado por",
        "en": "Modified by",
        "fr": "Modifié par",
        "es": "Modificado por"
    },
    {
        "id": "createdBy",
        "pt": "Criado por",
        "en": "Created by",
        "fr": "Créé par",
        "es": "Creado por"
    },
    {
        "id": "field",
        "pt": "Campo",
        "en": "Field",
        "fr": "Champ",
        "es": "Campo"
    },
    {
        "id": "editionsHistory",
        "pt": "HISTÓRICO DE EDIÇÕES",
        "en": "EDITIONS HISTORY",
        "fr": "HISTORIQUE DES ÉDITIONS",
        "es": "EDICIONES HISTORIA"
    },
    {
        "id": "place",
        "pt": "Local",
        "en": "Local",
        "fr": "Local",
        "es": "Local"
    },
    {
        "id": "city",
        "pt": "Cidade",
        "en": "City",
        "fr": "Ville",
        "es": "Ciudad"
    },
    {
        "id": "origin",
        "pt": "Origem",
        "en": "Source",
        "fr": "La source",
        "es": "Fuente"
    },
    {
        "id": "collectionPlace",
        "pt": "LOCAL DE RECRUTAMENTO",
        "en": "PLACE OF RECRUITMENT",
        "fr": "LIEU DE RECRUTEMENT",
        "es": "LUGAR DE RECLUTAMIENTO"
    },
    {
        "id": "entryId",
        "pt": "ID Registo",
        "en": "ID Record",
        "fr": "Enregistrement d'identification",
        "es": "Registro de identidad"
    },
    {
        "id": "status",
        "pt": "Estado",
        "en": "Status",
        "fr": "Statut",
        "es": "Estado"
    },
    {
        "id": "resendEmail",
        "pt": "Reenviar email",
        "en": "Resend email",
        "fr": "Ré-envoyer l'email",
        "es": "Reenviar email"
    },
    {
        "id": "generatePDF",
        "pt": "Gerar PDF",
        "en": "Generate PDF",
        "fr": "Générer un PDF",
        "es": "Generar PDF"
    },
    {
        "id": "toFile",
        "pt": "Arquivar",
        "en": "To file",
        "fr": "À déposer",
        "es": "Archivar"
    },
    {
        "id": "all",
        "pt": "Todos",
        "en": "All",
        "fr": "Tout",
        "es": "Todos"
    },
    {
        "id": "all_f",
        "pt": "Todas",
        "en": "All",
        "fr": "Tout",
        "es": "Todos"
    },
    {
        "id": "invalidDate",
        "pt": "Data inválida",
        "en": "Invalid date",
        "fr": "Date invalide",
        "es": "Fecha invalida"
    },
    {
        "id": "endDateInferiorError",
        "pt": "Insira uma data superior ou igual à data de início",
        "en": "Enter a date greater than or equal to the start date",
        "fr": "Entrez une date supérieure ou égale à la date de début",
        "es": "Ingrese una fecha mayor o igual a la fecha de inicio"
    },
    {
        "id": "monday",
        "pt": "2ª",
        "en": "Mon",
        "fr": "lun",
        "es": "Lun"
    },
    {
        "id": "tuesday",
        "pt": "3ª",
        "en": "Tue",
        "fr": "Mar",
        "es": "mar"
    },
    {
        "id": "wednesday",
        "pt": "4ª",
        "en": "Wed",
        "fr": "mer",
        "es": "Mie"
    },
    {
        "id": "thursday",
        "pt": "5ª",
        "en": "Thu",
        "fr": "jeu",
        "es": "Jue"
    },
    {
        "id": "friday",
        "pt": "6ª",
        "en": "Fri",
        "fr": "ven",
        "es": "Vie"
    },
    {
        "id": "saturday",
        "pt": "Sáb",
        "en": "Sat",
        "fr": "Sam",
        "es": "Sab"
    },
    {
        "id": "sunday",
        "pt": "Dom",
        "en": "Sun",
        "fr": "Soleil",
        "es": "Dom"
    },
    {
        "id": "emailSentSuccessfully",
        "pt": "Email enviado com sucesso",
        "en": "Email successfully sent",
        "fr": "Email envoyé avec succès",
        "es": "Correo electrónico enviado correctamente"
    },
    {
        "id": "credits",
        "pt": "Subscrições",
        "en": "Subscriptions",
        "fr": "Abonnements",
        "es": "Suscripciones"
    },
    {
        "id": "yes",
        "pt": "Sim",
        "en": "Yes",
        "fr": "Oui",
        "es": "Sí"
    },
    {
        "id": "no",
        "pt": "Não",
        "en": "Not",
        "fr": "ne pas",
        "es": "No"
    },
    {
        "id": "member",
        "pt": "Membro",
        "en": "Member",
        "fr": "Membre",
        "es": "Miembro"
    },
    {
        "id": "donor",
        "pt": "Doador",
        "en": "Donor",
        "fr": "Donneur",
        "es": "Donante"
    },
    {
        "id": "synchSuccess",
        "pt": "Sincronização concluida com sucesso.",
        "en": "Synchronization completed successfully.",
        "fr": "La synchronisation s'est terminée avec succès.",
        "es": "Sincronización completada con éxito."
    },
    {
        "id": "synchOnlyOnline",
        "pt": "Necessita de estar online para sincronizar os dados.",
        "en": "You need to be online to synchronize your data.",
        "fr": "Vous devez être en ligne pour synchroniser vos données.",
        "es": "Necesitas estar en línea para sincronizar tus datos."
    },
    {
        "id": "emailSender",
        "pt": "Remetente de e-mail",
        "en": "Email sender",
        "fr": "Email de l'expéditeur",
        "es": "Remitente de correo electrónico"
    },
    {
        "id": "RowsPerPage",
        "pt": "Linhas por página:",
        "en": "Lines per page:",
        "fr": "Lignes par page:",
        "es": "Líneas por página:"
    },
    {
        "id": "citizenCardPTdigitsError",
        "pt": "Não é um bi/cc portugês válido.",
        "en": "It is not a valid Portuguese Id Number.",
        "fr": "Ce n'est pas un numéro d'identification portugais valide.",
        "es": "No es un número de identificación portugués válido."
    },
    {
        "id": "geolocationError",
        "pt": "Ocorreu um erro ao tentar determinar a sua localização. Por favor, indique em baixo a sua localização.",
        "en": "There was an error while trying to determine your location. Please enter your location below.",
        "fr": "Il y avait une erreur en essayant de déterminer votre emplacement. S'il vous plaît entrer votre emplacement ci-dessous.",
        "es": "Se produjo un error al intentar determinar su ubicación. Por favor ingrese su ubicación a continuación."
    },
    {
        "id": "geolocationError1",
        "pt": "Tem de permitir o acesso à sua localização para que a submissão possa ser concluída.",
        "en": "You must allow access to your location so that the submission can be completed.",
        "fr": "Vous devez autoriser l'accès à votre emplacement pour que la soumission puisse être complétée.",
        "es": "Debe permitir el acceso a su ubicación para que se pueda completar el envío."
    },
    {
        "id": "geolocationError2",
        "pt": "Ocorreu um erro ao determinar a sua localização. Tente novamente.",
        "en": "There was an error determining your location. Try again.",
        "fr": "Une erreur s'est produite lors de la détermination de votre position. Réessayer.",
        "es": "Hubo un error al determinar su ubicación. Inténtalo de nuevo."
    },
    {
        "id": "geolocationError3",
        "pt": "Tempo excedido ao tentar determinar a sua localização. Tente novamente.",
        "en": "Timeout when trying to determine your location. Try again.",
        "fr": "Délai d'attente lorsque vous essayez de déterminer votre emplacement. Réessayer.",
        "es": "Tiempo de espera al intentar determinar su ubicación. Inténtalo de nuevo."
    },
    {
        "id": "number.max",
        "pt": "Insira no máximo <{value}> caracteres.",
        "en": "Please enter at most <{value}> characters.",
        "fr": "Veuillez entrer au plus <{value}> caractères.",
        "es": "Por favor ingrese un máximo de <{value}> caracteres."
    },
    {
        "id": "STATUS_500",
        "pt": "Ocorreu um erro com o servidor. Tente mais tarde.",
        "en": "An error has occurred with the server. Try later.",
        "fr": "Une erreur s'est produite avec le serveur. Essayer plus tard.",
        "es": "Ha ocurrido un error con el servidor. Intenta más tarde."
    },
    {
        "id": "number.base",
        "pt": "Só pode inserir números.",
        "en": "You can only enter numbers.",
        "fr": "Vous ne pouvez entrer que des chiffres.",
        "es": "Sólo puedes ingresar números."
    },
    {
        "id": "string.alphanum",
        "pt": "Insira apenas letras ou números.",
        "en": "Enter only letters or numbers.",
        "fr": "Entrez uniquement des lettres ou des chiffres.",
        "es": "Ingrese solo letras o números."
    },
    {
        "id": "string.min",
        "pt": "Insira no minimo <{value}> caracteres.",
        "en": "Enter at least <{value}> characters.",
        "fr": "Entrez au moins <{value}> caractères.",
        "es": "Introduzca al menos <{value}> caracteres."
    },
    {
        "id": "string.email",
        "pt": "<{value}> tem de ter um formato de email válido.",
        "en": "<{value}> must have a valid email format.",
        "fr": "<{value}> doit avoir un format de courrier électronique valide.",
        "es": "<{value}> debe tener un formato de correo electrónico válido."
    },
    {
        "id": "array.min",
        "pt": "Deve adicionar uma opção",
        "en": "You must add an option",
        "fr": "Doit ajouter une option",
        "es": "Debe agregar una opción"
    },
    {
        "id": "confirmPassword.any.allowOnly",
        "pt": "Passwords têm de coincidir.",
        "en": "Passwords must match.",
        "fr": "Les mots de passe doivent correspondre.",
        "es": "Las contraseñas deben coincidir."
    },
    {
        "id": "password.string.regex.base",
        "pt": "A password deve conter pelo menos um número e uma letra.",
        "en": "The password must contain at least one number and one letter.",
        "fr": "Le mot de passe doit contenir au moins un chiffre et une lettre.",
        "es": "La contraseña debe contener al menos un número y una letra."
    },
    {
        "id": "INCORRECT_PASSWORD",
        "pt": "username e/ou password incorreto.",
        "en": "Username and/or password incorrect.",
        "fr": "Nom d'utilisateur et / ou mot de passe incorrect.",
        "es": "Nombre de usuario y / o contraseña incorrectos."
    },
    {
        "id": "INACTIVE_USER",
        "pt": "Este utilizador não existe.",
        "en": "This user does not exist.",
        "fr": "Cet utilisateur n'existe pas.",
        "es": "Este usuario no existe."
    },
    {
        "id": "amountError",
        "pt": "Por favor selecione um montante.",
        "en": "Please select an amount.",
        "fr": "Veuillez sélectionner un montant.",
        "es": "Por favor seleccione una cantidad."
    },
    {
        "id": "invalidPTFiscalId",
        "pt": "Não é um NIF português válido.",
        "en": "It is not a valid Portuguese Fiscal Id.",
        "fr": "Ce n'est pas un numéro d'identification fiscale portugais valide.",
        "es": "No es una identificación fiscal portuguesa válida."
    },
    {
        "id": "invalidIBAN",
        "pt": "Por favor, insira um IBAN válido.",
        "en": "Please enter a valid IBAN.",
        "fr": "Veuillez entrer un IBAN valide.",
        "es": "Por favor ingrese un IBAN válido."
    },
    {
        "id": "form_mandatoryFields",
        "pt": "( * ) campos obrigatórios",
        "en": "( * ) Mandatory fields",
        "fr": "( * ) Champs obligatoires",
        "es": "( * ) Campos obligatorios"
    },
    {
        "id": "mandatoryField",
        "pt": "Campo Obrigatório.",
        "en": "Mandatory field.",
        "fr": "Champ obligatoire",
        "es": "Campo obligatorio."
    },
    {
        "id": "phonePTdigitsError",
        "pt": "Não é um contacto português válido.",
        "en": "It is not a valid Portuguese contact number.",
        "fr": "Ce n'est pas un numéro de contact portugais valide.",
        "es": "No es un número de contacto portugués válido."
    },
    {
        "id": "invalidPTpostalCode",
        "pt": "Insira um código postal válido.",
        "en": "Please enter a valid zip code.",
        "fr": "Veuillez entrer un code postal valide.",
        "es": "Por favor ingrese un código postal válido."
    },
    {
        "id": "DUPLICATE_USER",
        "pt": "Este email já se encontra registado. Por favor, indique um diferente.",
        "en": "This email is already registered. Please enter a different one.",
        "fr": "Cet e-mail est déjà enregistré. S'il vous plaît entrer un autre.",
        "es": "Este correo electrónico ya está registrado. Por favor ingrese uno diferente."
    },
    {
        "id": "invalidEmail",
        "pt": "Por favor, insira um email válido.",
        "en": "Please enter a valid email address.",
        "fr": "S'il vous plaît, mettez une adresse email valide.",
        "es": "Por favor, introduce una dirección de correo electrónico válida."
    },
    {
        "id": "success",
        "pt": "Sucesso",
        "en": "Success",
        "fr": "Succès",
        "es": "Éxito"
    },
    {
        "id": "confirm",
        "pt": "Confirmar",
        "en": "Confirm",
        "fr": "Confirmer",
        "es": "Confirmar"
    },
    {
        "id": "update",
        "pt": "Atualizar",
        "en": "Update",
        "fr": "Mettre à jour",
        "es": "Actualizar"
    },
    {
        "id": "passwordResetEmailSent",
        "pt": "Email de Reset de Password Enviado",
        "en": "Password Reset Email Sent",
        "fr": "Mot de passe réinitialisé Email envoyé",
        "es": "Restablecer contraseña de correo electrónico enviado"
    },
    {
        "id": "passwordResetEmailSentTo",
        "pt": "Foi enviado um email para, <b><{value}></b>. Siga as instruções no email para fazer reset à sua password.",
        "en": "An email has been sent to <b> <{value}> </ b>. Follow the instructions in the email to reset your password.",
        "fr": "Un email a été envoyé à <b> <{value}> </ b>. Suivez les instructions dans l'e-mail pour réinitialiser votre mot de passe.",
        "es": "Se ha enviado un correo electrónico a <b> <{value}> </ b>. Siga las instrucciones en el correo electrónico para restablecer su contraseña."
    },
    {
        "id": "lostYourPasswordTitle",
        "pt": "Perdeu a sua password? Por favor insira o seu username. Receberá um link para criar uma nova password.",
        "en": "Lost your password? Please enter your username. You will receive a link to create a new password.",
        "fr": "Mot de passe perdu? S'il vous plaît entrez votre nom d'utilisateur. Vous recevrez un lien pour créer un nouveau mot de passe.",
        "es": "¿Perdiste tu contraseña? Por favor, ingrese su nombre de usuario. Recibirá un enlace para crear una nueva contraseña."
    },
    {
        "id": "forgotPassword?",
        "pt": "Esqueceu-se da password?",
        "en": "Forgot your password?",
        "fr": "Mot de passe oublié?",
        "es": "¿Olvidaste tu contraseña?"
    },
    {
        "id": "Cancelled",
        "pt": "Cancelado",
        "en": "Canceled",
        "fr": "Annulé",
        "es": "Cancelado"
    },
    {
        "id": "cancel",
        "pt": "Cancelar",
        "en": "Cancel",
        "fr": "Annuler",
        "es": "Cancelar"
    },
    {
        "id": "enter",
        "pt": "Entrar",
        "en": "Login",
        "fr": "S'identifier",
        "es": "Iniciar sesión"
    },
    {
        "id": "login",
        "pt": "Iniciar Sessão",
        "en": "Begin session",
        "fr": "Commencer la session",
        "es": "Iniciar sesion"
    },
    {
        "id": "fullName",
        "pt": "Nome Completo",
        "en": "Full name",
        "fr": "Nom complet",
        "es": "Nombre completo"
    },
    {
        "id": "address_street",
        "pt": "Morada",
        "en": "Address",
        "fr": "Adresse",
        "es": "Dirección"
    },
    {
        "id": "postalCode",
        "pt": "Código Postal",
        "en": "Postal Code",
        "fr": "code postal",
        "es": "Código postal"
    },
    {
        "id": "address_city",
        "pt": "Localidade",
        "en": "City",
        "fr": "Ville",
        "es": "Ciudad"
    },
    {
        "id": "country",
        "pt": "País",
        "en": "Country",
        "fr": "Pays",
        "es": "País"
    },
    {
        "id": "fiscalId",
        "pt": "NIF",
        "en": "Fiscal Id",
        "fr": "Identité fiscale",
        "es": "Identificación fiscal"
    },
    {
        "id": "niss",
        "pt": "NISS",
        "en": "Social Security Number",
        "fr": "Numéro de sécurité sociale",
        "es": "Número de seguridad social"
    },
    {
        "id": "mobile",
        "pt": "Telemóvel",
        "en": "Mobile Phone",
        "fr": "Téléphone portable",
        "es": "Teléfono móvil"
    },
    {
        "id": "phone",
        "pt": "Telefone",
        "en": "Telephone",
        "fr": "Téléphone",
        "es": "Teléfono"
    },
    {
        "id": "verifyFormFields",
        "pt": "Por favor, verifique os campos do formulário.",
        "en": "Please check the form fields.",
        "fr": "Veuillez vérifier les champs du formulaire.",
        "es": "Por favor, compruebe los campos del formulario."
    },
    {
        "id": "minAmountError",
        "pt": "O valor minimo são <{value}>€",
        "en": "The minimum value is <{value}> €",
        "fr": "La valeur minimale est <{value}> €",
        "es": "El valor mínimo es <{value}> €"
    },
    {
        "id": "createNewPassword",
        "pt": "Criar nova Palavra Passe",
        "en": "Create New Password",
        "fr": "Créer un nouveau mot de passe",
        "es": "Crear nueva contraseña"
    },
    {
        "id": "showPassword",
        "pt": "mostrar password",
        "en": "Show password",
        "fr": "Montrer le mot de passe",
        "es": "Mostrar contraseña"
    },
    {
        "id": "createUser",
        "pt": "Criar Utilizador",
        "en": "Create User",
        "fr": "Créer un utilisateur",
        "es": "Crear usuario"
    },
    {
        "id": "editUser",
        "pt": "Editar Utilizador",
        "en": "Edit User",
        "fr": "Modifier l'utilisateur",
        "es": "editar usuario"
    },
    {
        "id": "notifyUserToDefinePassword",
        "pt": "Notificar utilizador para definir a sua password",
        "en": "Notify user to set their password",
        "fr": "Informer l'utilisateur de définir son mot de passe",
        "es": "Notificar al usuario para establecer su contraseña"
    },
    {
        "id": "close",
        "pt": "Fechar",
        "en": "Close",
        "fr": "Fermer",
        "es": "Cerrar"
    },
    {
        "id": "createNew",
        "pt": "Criar novo",
        "en": "Create new",
        "fr": "Créer un nouveau",
        "es": "Crear nuevo"
    },
    {
        "id": "userCreatedSuccesfully",
        "pt": "Utilizador criado com successo",
        "en": "Successfully created user",
        "fr": "Utilisateur créé avec succès",
        "es": "Usuario creado con éxito"
    },
    {
        "id": "NO_USER",
        "pt": "Não existe nenhum utilizador com este username",
        "en": "There is no user with this username",
        "fr": "Il n'y a pas d'utilisateur avec ce nom d'utilisateur",
        "es": "No hay usuario con este nombre de usuario"
    },
    {
        "id": "PASSWORD_NOT_CHANGED",
        "pt": "O token inserido já não é válido",
        "en": "The token entered is no longer valid",
        "fr": "Le jeton entré n'est plus valide",
        "es": "El token introducido ya no es válido"
    },
    {
        "id": "donate",
        "pt": "Doar",
        "en": "Donate",
        "fr": "Faire un don",
        "es": "Donar"
    },
    {
        "id": "next",
        "pt": "Seguinte",
        "en": "Next",
        "fr": "Suivant",
        "es": "Siguiente"
    },
    {
        "id": "back",
        "pt": "Voltar",
        "en": "Return",
        "fr": "Revenir",
        "es": "Regreso"
    },
    {
        "id": "userList",
        "pt": "Lista de Utilizadores",
        "en": "User List",
        "fr": "liste d'utilisateur",
        "es": "Lista de usuarios"
    },
    {
        "id": "userUpdatedSuccesfully",
        "pt": "Utilizador editado com successo",
        "en": "User edit sucessfully",
        "fr": "Utilisateur éditer avec succès",
        "es": "Edición de usuario con éxito"
    },
    {
        "id": "All",
        "pt": "Todos",
        "en": "All",
        "fr": "Tout",
        "es": "Todos"
    },
    {
        "id": "Active",
        "pt": "Ativo",
        "en": "Active",
        "fr": "actif",
        "es": "Activo"
    },
    {
        "id": "Actives",
        "pt": "Ativos",
        "en": "Active",
        "fr": "actif",
        "es": "Activo"
    },
    {
        "id": "Inactive",
        "pt": "Inativos",
        "en": "Inactive",
        "fr": "Inactif",
        "es": "Inactivo"
    },
    {
        "id": "create",
        "pt": "Criar",
        "en": "Create",
        "fr": "Créer",
        "es": "Crear"
    },
    {
        "id": "users",
        "pt": "Utilizadores",
        "en": "Users",
        "fr": "Utilisateurs",
        "es": "Usuarios"
    },
    {
        "id": "form_submission_successful",
        "pt": "Formulário submetido com sucesso.",
        "en": "Form submitted successfully.",
        "fr": "Formulaire soumis avec succès.",
        "es": "Formulario enviado con éxito."
    },
    {
        "id": "form_submission_no_connection",
        "pt": "Não conseguimos conectar com o servidor. Sincronize os dados mais tarde.",
        "en": "We were unable to connect to the server. Synchronize the data later.",
        "fr": "Nous n'avons pas pu nous connecter au serveur. Synchronisez les données plus tard.",
        "es": "No hemos podido conectarnos al servidor. Sincronizar los datos más tarde."
    },
    {
        "id": "form_submission_activateLocation",
        "pt": "Não conseguimos conectar com o servidor. Sincronize os dados mais tarde. Necessita de ativar a permissão de localização. <a class='underline' href='/permissionIntructions'>Clique aqui e siga as intruções na página.</a>",
        "en": "We were unable to connect to the server. Synchronize the data later. You need to enable location permission. <a class='underline' href='/permissionIntructions'> Click here and follow the instructions on the page. </a>",
        "fr": "Nous n'avons pas pu nous connecter au serveur. Synchronisez les données plus tard. Vous devez activer l'autorisation d'emplacement. <a class='underline' href='/permissionIntructions'> Cliquez ici et suivez les instructions à la page. </a>",
        "es": "No hemos podido conectarnos al servidor. Sincronizar los datos más tarde. Necesitas habilitar el permiso de ubicación. <a class='underline 'href='/permissionIntructions'> Haga clic aquí y siga las instrucciones de la página. </a>"
    },
    {
        "id": "reportsNoRecords",
        "pt": "Não existem registos definidos para este período de tempo",
        "en": "No records defined for this time period",
        "fr": "Aucun enregistrement défini pour cette période",
        "es": "No hay registros definidos para este período de tiempo"
    },
    {
        "id": "form_submission_noBalance",
        "pt": "Não existem créditos disponíveis. Contacte a organização.",
        "en": "No credits available. Contact the organization.",
        "fr": "Aucun crédit disponible. Contactez l'organisation.",
        "es": "No hay créditos disponibles. Póngase en contacto con la organización."
    },
    {
        "id": "formAtLeastOneValueMandatory",
        "pt": "Pelo menos um destes campos, <{value}>, tem de ser preenchido.",
        "en": "At least one of these fields, <{value}>, must be filled in.",
        "fr": "Au moins un de ces champs, <{value}>, doit être renseigné.",
        "es": "Al menos uno de estos campos, <{value}>, se debe completar."
    },
    {
        "id": "Completes",
        "pt": "Completos",
        "en": "Complete",
        "fr": "Achevée",
        "es": "Completar"
    },
    {
        "id": "Incompletes",
        "pt": "Incompletos",
        "en": "Incomplete",
        "fr": "Incomplet",
        "es": "Incompleto"
    },
    {
        "id": "Complete",
        "pt": "Completo",
        "en": "Complete",
        "fr": "Achevée",
        "es": "Completar"
    },
    {
        "id": "Incomplete",
        "pt": "Incompleto",
        "en": "Incomplete",
        "fr": "Incomplet",
        "es": "Incompleto"
    },
    {
        "id": "save",
        "pt": "Guardar",
        "en": "Save",
        "fr": "sauvegarder",
        "es": "Salvar"
    },
    {
        "id": "Validated",
        "pt": "Validado",
        "en": "Validated",
        "fr": "Validé",
        "es": "Validado"
    },
    {
        "id": "Not validated",
        "pt": "Não validado",
        "en": "Not validated",
        "fr": "Pas validé",
        "es": "No validado"
    },
    {
        "id": "Pending",
        "pt": "Pendente",
        "en": "Pending",
        "fr": "En attente",
        "es": "Pendiente"
    },
    {
        "id": "validationDate",
        "pt": "Data de validação",
        "en": "Validation date",
        "fr": "Date de validation",
        "es": "Fecha de validación"
    },
    {
        "id": "Call notes",
        "pt": "Notas da chamada",
        "en": "Call notes",
        "fr": "Notes d'appel",
        "es": "Notas de llamada"
    },
    {
        "id": "callNotes",
        "pt": "Notas da chamada",
        "en": "Call notes",
        "fr": "Notes d'appel",
        "es": "Notas de llamada"
    },
    {
        "id": "organization",
        "pt": "Organização",
        "en": "Organization",
        "fr": "Organisation",
        "es": "Organización"
    },
    {
        "id": "organization_edit_success",
        "pt": "Dados editados com sucesso",
        "en": "Data edited successfully",
        "fr": "Données éditées avec succès",
        "es": "Datos editados exitosamente"
    },
    {
        "id": "Unread",
        "pt": "Por ler",
        "en": "For reading",
        "fr": "A lire",
        "es": "Para leer"
    },
    {
        "id": "Closed",
        "pt": "Fechado",
        "en": "Closed",
        "fr": "Fermé",
        "es": "Cerrado"
    },
    {
        "id": "In Progress",
        "pt": "Em progresso",
        "en": "In progress",
        "fr": "En cours",
        "es": "En progreso"
    },
    {
        "id": "timeInProgress",
        "pt": "a decorrer",
        "en": "in progress",
        "fr": "en cours",
        "es": "en progreso"
    },
    {
        "id": "TimeInProgress",
        "pt": "A decorrer",
        "en": "In progress",
        "fr": "En cours",
        "es": "En progreso"
    },
    {
        "id": "eq",
        "pt": "Igual a",
        "en": "Equal to",
        "fr": "Égal à",
        "es": "Igual a"
    },
    {
        "id": "ne",
        "pt": "Diferente de",
        "en": "Different than",
        "fr": "Différent de",
        "es": "Diferente que"
    },
    {
        "id": "gt",
        "pt": "Maior que",
        "en": "Bigger then",
        "fr": "Plus gros alors",
        "es": "Más grande entonces"
    },
    {
        "id": "gte",
        "pt": "Maior ou igual",
        "en": "Bigger or equal",
        "fr": "Plus grand ou égal",
        "es": "Mayor o igual"
    },
    {
        "id": "lt",
        "pt": "Menor que",
        "en": "Less than",
        "fr": "Moins que",
        "es": "Menos que"
    },
    {
        "id": "lte",
        "pt": "Menor ou igual",
        "en": "Less or equal",
        "fr": "Moins ou égal",
        "es": "Menor o igual"
    },
    {
        "id": "in",
        "pt": "Contém",
        "en": "Contains",
        "fr": "Contient",
        "es": "Contiene"
    },
    {
        "id": "nin",
        "pt": "Não contém",
        "en": "Does not contain",
        "fr": "Ne contient pas",
        "es": "No contiene"
    },
    {
        "id": "ALL_DATES",
        "pt": "Desde sempre",
        "en": "Since always",
        "fr": "Depuis toujours",
        "es": "Desde siempre"
    },
    {
        "id": "CUSTOM",
        "pt": "Personalizado",
        "en": "Custom",
        "fr": "Douane",
        "es": "Personalizado"
    },
    {
        "id": "TODAY",
        "pt": "Hoje",
        "en": "Today",
        "fr": "Aujourd'hui",
        "es": "Hoy"
    },
    {
        "id": "Week",
        "pt": "Semana",
        "en": "Week",
        "fr": "La semaine",
        "es": "Semana"
    },
    {
        "id": "YESTERDAY",
        "pt": "Ontem",
        "en": "Yesterday",
        "fr": "Hier",
        "es": "Ayer"
    },
    {
        "id": "THIS_WEEK",
        "pt": "Esta semana",
        "en": "This week",
        "fr": "Cette semaine",
        "es": "Esta semana"
    },
    {
        "id": "PREVIOUS_WEEK",
        "pt": "Semana anterior",
        "en": "Last week",
        "fr": "La semaine dernière",
        "es": "La semana pasada"
    },
    {
        "id": "THIS_AND_PREVIOUS_WEEK",
        "pt": "Esta semana e anterior",
        "en": "This week and earlier",
        "fr": "Cette semaine et plus tôt",
        "es": "Esta semana y antes"
    },
    {
        "id": "THIS_MONTH",
        "pt": "Este mês",
        "en": "This month",
        "fr": "Ce mois-ci",
        "es": "Este mes"
    },
    {
        "id": "Month",
        "pt": "Mês",
        "en": "Month",
        "fr": "Mois",
        "es": "Mes"
    },
    {
        "id": "PREVIOUS_MONTH",
        "pt": "Mês anterior",
        "en": "Last month",
        "fr": "Le mois dernier",
        "es": "El mes pasado"
    },
    {
        "id": "THIS_AND_PREVIOUS_MONTH",
        "pt": "Este mês e anterior",
        "en": "This month and earlier",
        "fr": "Ce mois et plus tôt",
        "es": "Este mes y antes"
    },
    {
        "id": "THIS_YEAR",
        "pt": "Este ano",
        "en": "This year",
        "fr": "Cette année",
        "es": "Este año"
    },
    {
        "id": "PREVIOUS_YEAR",
        "pt": "Ano anterior",
        "en": "Last year",
        "fr": "L'année dernière",
        "es": "El año pasado"
    },
    {
        "id": "THIS_AND_PREVIOUS_YEAR",
        "pt": "Este ano e anterior",
        "en": "This year and earlier",
        "fr": "Cette année et plus tôt",
        "es": "Este año y antes"
    },
    {
        "id": "THIS_QUARTER",
        "pt": "Este trimestre",
        "en": "This quarter",
        "fr": "Ce trimestre",
        "es": "Este cuarto"
    },
    {
        "id": "PREVIOUS_QUARTER",
        "pt": "Trimestre anterior",
        "en": "Previous quarter",
        "fr": "Trimestre précédent",
        "es": "Trimestre anterior"
    },
    {
        "id": "THIS_AND_PREVIOUS_QUARTER",
        "pt": "Este trimestre e anterior",
        "en": "This quarter and earlier",
        "fr": "Ce trimestre et plus tôt",
        "es": "Este trimestre y anteriores."
    },
    {
        "id": "recordCount",
        "pt": "Contagem de registos",
        "en": "Record count",
        "fr": "Nombre d'enregistrements",
        "es": "Número de registros"
    },
    {
        "id": "HORIZONTAL_BAR",
        "pt": "Barras",
        "en": "Bars",
        "fr": "Les bars",
        "es": "Barras"
    },
    {
        "id": "VERTICAL_BAR",
        "pt": "Colunas",
        "en": "Columns",
        "fr": "Les colonnes",
        "es": "Columnas"
    },
    {
        "id": "LINE",
        "pt": "Linha",
        "en": "Line",
        "fr": "Ligne",
        "es": "Línea"
    },
    {
        "id": "DOUGHNUT",
        "pt": "Donut",
        "en": "Donut",
        "fr": "Donut",
        "es": "Rosquilla"
    },
    {
        "id": "language",
        "pt": "Idioma",
        "en": "Language",
        "fr": "La langue",
        "es": "Idioma"
    },
    {
        "id": "age",
        "pt": "Idade",
        "en": "Idade",
        "fr": "Âge",
        "es": "Años"
    }
]

export const getLabel = (key, lang, defaultLabel, auxValue) => {
    const labelData = labels.filter(l => l.id === key);
    if(labelData.length > 0){
        const translatedLabel = (lang && labelData[0][lang]) ? labelData[0][lang] : labelData[0].pt;
        if(translatedLabel && translatedLabel.indexOf("<{value}>") !== -1){
            return translatedLabel.replace("<{value}>", auxValue);
        }
        return translatedLabel;
    }
    return defaultLabel;
}