import React, { Component } from 'react';
import NavLink from "react-router-dom/NavLink";
import Link from "react-router-dom/Link";
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import FormIcon from '@material-ui/icons/Favorite';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import NoteIcon from '@material-ui/icons/Note';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DownArrowIcon from '@material-ui/icons/ArrowDropDown';
import TimeIcon from '@material-ui/icons/Timer';
import GoalsIcon from '@material-ui/icons/Stars';
import ComissionsIcon from '@material-ui/icons/AttachMoney';
import UserImage from './UserImage';
import Tooltip from '@material-ui/core/Tooltip';
/*import LocationOnIcon from '@material-ui/icons/LocationOn';*/
const labels = require("../labels");

class SideMenu extends Component {

    state = {
        anchorEl: null,
    };

    openProfileMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    }
    closeProfileMenu = () => {
        this.setState({ anchorEl: null });   
    }

    handleLogout = (logout) => {
        this.closeProfileMenu();
        this.props.toggleSideMenu();
        logout();
    }

    handleProfileMenuItemClick = () => {
        this.closeProfileMenu();
        this.props.toggleSideMenu();
    }

    handleLinkClick = (e) => {
        if(this.props.online === false) e.preventDefault();
        else this.props.toggleSideMenu();
    }

    render() {
        const { userData, open, toggleSideMenu, logout, online, language } = this.props;
        const { anchorEl } = this.state;

        const linkClassName = (online === false) 
            ? "menu-item disabled" 
            : "menu-item";
            
        const disabledLinkTooltipLable = (online === false) 
            ? labels.getLabel("onlyOnline", language) 
            : "";

        const reportsActive = (match, location) => location.pathname === "/reports-folders" || location.pathname === "/report";

        return (
            <Drawer 
                open={open}
                onClose={toggleSideMenu}
            >
                <div className="side-menu">

                    <div 
                        className="menu-item profile-menu"
                        aria-owns={anchorEl ? 'profile-menu' : null}
                        aria-haspopup="true"
                        onClick={this.openProfileMenu}
                    >
                        <UserImage userImage={userData.userImage} />
                        <div className="text">
                            <Typography className="name">
                                {userData.firstName+' '+userData.lastName}
                            </Typography>
                            <Typography className="profile">
                                {userData.profile}
                            </Typography>
                        </div>
                        <DownArrowIcon className="arrow-down icon" />
                    </div>
                    <Menu
                        id="profile-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.closeProfileMenu}
                    >
                        <Link to="/user-setup/personal-data">
                            <MenuItem onClick={this.handleProfileMenuItemClick}>
                                {labels.getLabel("personalData", language)}
                            </MenuItem>
                        </Link>
                        <MenuItem onClick={() => this.handleLogout(logout)}>
                            {labels.getLabel("logout", language)}
                        </MenuItem>
                    </Menu>

                    <NavLink to="/home" className="menu-item" activeClassName="active" onClick={toggleSideMenu}>
                        <HomeIcon className="icon" />
                        <span className="text" >
                            {labels.getLabel("Home", language)}
                        </span>
                    </NavLink>

                    <NavLink to="/form" className="menu-item" activeClassName="active" onClick={toggleSideMenu}>
                        <FormIcon className="icon" />
                        <span className="text">
                            {labels.getLabel("Form", language)}
                        </span>
                    </NavLink>

                    <Tooltip title={disabledLinkTooltipLable} placement="right">
                        <NavLink to="/calendar" className={linkClassName} activeClassName="active" onClick={this.handleLinkClick}>
                            <CalendarIcon className="icon"/>
                            <span className="text">
                                {labels.getLabel("Calendar", language)}
                            </span>
                        </NavLink>
                    </Tooltip>
                    
                    {userData.profile !== "Recruiter" &&
                        <div>        
                            <Tooltip title={disabledLinkTooltipLable} placement="right">
                                <NavLink to="/teams" className={linkClassName} activeClassName="active" onClick={this.handleLinkClick}>
                                    <PeopleIcon className="icon"/>
                                    <span className="text">
                                        {labels.getLabel("Teams", language)}
                                    </span>
                                </NavLink>
                            </Tooltip>
                            <Tooltip title={disabledLinkTooltipLable} placement="right">
                                <NavLink to="/reports-folders" className={linkClassName} activeClassName="active" onClick={this.handleLinkClick} isActive={reportsActive}>
                                    <ListIcon className="icon"/>
                                    <span className="text">
                                        {labels.getLabel("Reports", language)}
                                    </span>
                                </NavLink>
                            </Tooltip>
                        </div>
                    }
                    
                    <Tooltip title={disabledLinkTooltipLable} placement="right">
                        <NavLink to="/entriesNotes" className={linkClassName} activeClassName="active" onClick={this.handleLinkClick}>
                            <NoteIcon className="icon"/>
                            <span className="text">
                                {labels.getLabel("Notes", language)}
                            </span>
                        </NavLink>
                    </Tooltip>

                    {userData.profile !== "Recruiter" &&
                        <Tooltip title={disabledLinkTooltipLable} placement="right">
                            <NavLink to='/times' className={linkClassName} activeClassName="active" onClick={this.handleLinkClick}>
                                <TimeIcon className="icon"/>
                                <span className="text">
                                    {labels.getLabel("Times", language)}
                                </span>
                            </NavLink>
                        </Tooltip>
                    }

                    {userData.profile !== "Recruiter" && userData.profile !== "Team Leader" &&
                        <Tooltip title={disabledLinkTooltipLable} placement="right">
                            <NavLink to='/bonus' className={linkClassName} activeClassName="active" onClick={this.handleLinkClick}>
                                <ComissionsIcon className="icon"/>
                                <span className="text">
                                    Bonus
                                </span>
                            </NavLink>
                        </Tooltip>
                    }

                    {userData.profile === "Administrator" &&
                        <div>
                            <Tooltip title={disabledLinkTooltipLable} placement="right">
                                <NavLink to='/goalsBuilder' className={linkClassName} activeClassName="active" onClick={this.handleLinkClick}>
                                    <GoalsIcon className="icon"/>
                                    <span className="text">
                                        {labels.getLabel("Goals", language)}
                                    </span>
                                </NavLink>
                            </Tooltip>
                        </div>
                    }

                    {userData.profile === "Administrator" &&
                        <div>
                            {/*<NavLink to="/locations" className="menu-item" activeClassName="active" onClick={toggleSideMenu}>
                                <LocationOnIcon className="icon"/>
                                <span className="text">Localizações</span>
                            </NavLink>*/}
                            <Tooltip title={disabledLinkTooltipLable} placement="right">
                                <NavLink to='/setup' className={linkClassName} activeClassName="active" onClick={this.handleLinkClick}>
                                    <SettingsIcon className="icon"/>
                                    <span className="text">
                                        {labels.getLabel("Setup", language)}
                                    </span>
                                </NavLink>
                            </Tooltip>
                        </div>
                    }

                    {online === false &&
                        <div className="offline">
                            <Typography>
                                {labels.getLabel("offlineMode", language)}
                            </Typography>
                        </div>
                    }

                    <div id="lastBuild">
                        Last Update:
                        <div>Wed Jul 05 2023 08:30</div>
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default SideMenu;