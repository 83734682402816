import axios from 'axios';
const host = (process.env.NODE_ENV === "development") ? require("./config.json").developerHost : require("./config.json").productionHost;
const labels = require("./labels");
const moment = require('moment');

export const months = (language) => ({
    0 : labels.getLabel("January", language),
    1 : labels.getLabel("February", language),
    2 : labels.getLabel("March", language),
    3 : labels.getLabel("April", language),
    4 : labels.getLabel("May", language),
    5 : labels.getLabel("June", language),
    6 : labels.getLabel("July", language),
    7 : labels.getLabel("August", language),
    8 : labels.getLabel("September", language),
    9 : labels.getLabel("October", language),
    10 : labels.getLabel("November", language),
    11 : labels.getLabel("December", language)
});

export function getSearchResult(parameter, showName){
    return this.getDonationsList()
    .then(res => {
        let searchSuggestions = []
        res.data.forEach(r => {
            const formValues = r.attributes.formValues;
            const entryAuxId = r.id.substring(0, 4);
            if(showName && (formValues.name === parameter || formValues.name.indexOf(parameter) === 0))
                searchSuggestions.push({
                    id: r.id, 
                    value:formValues.name
                });
            else if(entryAuxId === parameter || entryAuxId.indexOf(parameter) === 0)
                searchSuggestions.push({
                    id: r.id, 
                    value:entryAuxId
                });
            else if(formValues.fiscalId === parameter || formValues.fiscalId.indexOf(parameter) === 0)
                searchSuggestions.push({
                    id: r.id, 
                    value:formValues.fiscalId
                });
            else if(formValues.email === parameter || formValues.email.indexOf(parameter) === 0)
                searchSuggestions.push({
                    id: r.id, 
                    value:formValues.email
                });
            else if(formValues.mobile === parameter || formValues.mobile.indexOf(parameter) === 0)
                searchSuggestions.push({
                    id: r.id, 
                    value:formValues.mobile
                });
        });
        return searchSuggestions;
    })
}

export function getURLParameterByName(name, url){
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function getFormatedBirthdate(birthdate){
    return moment(birthdate).locale('pt').format('L');
}

export function getFormattedDate(date, withTime) {
    let day = moment(Number(date));
    if(!day.isValid()) day = moment(date);
    if(!withTime)
        return day.locale('pt').format('L');
    else if(withTime === true)
        return day.locale('pt').format('L') + ' ' + day.locale('pt').format('LT')
    else if(withTime === 'onlyTime')
        return day.locale('pt').format('LT')
}

export function get24hTime(str){
    str = String(str).toLowerCase().replace(/\s/g, '');
    var has_am = str.indexOf('am') >= 0;
    var has_pm = str.indexOf('pm') >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace('am', '').replace('pm', '');
    // if hour, convert to hour:00
    if (str.indexOf(':') < 0) str = str + ':00';
    // now it's hour:minute
    // we add am/pm back if striped out before 
    if (has_am) str += ' am';
    if (has_pm) str += ' pm';
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us 
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function(n){
        return (parseInt(n) < 10) ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
}

export function getTimeDifference(date1, date2){
    let dt1 = moment(date1);
    let dt2 = moment(date2);
    let difference = dt2.diff(dt1);
    let duration = moment.duration(difference); 

    let hours = duration.hours();
    let minutes = (duration.minutes() < 10 ? '0'+duration.minutes() : duration.minutes());
    let seconds = (duration.seconds() < 10 ? '0'+duration.seconds() : duration.seconds());

    return (
        (hours > 0 ? "<strong>"+hours+"</strong>" : hours)
        +':'+
        (minutes > 0 && hours === 0 ? "<strong>"+minutes+"</strong>" : minutes)
        +':'+
        (seconds > 0 && minutes === 0 ? "<strong>"+seconds+"</strong>" : seconds)
    );
}

export function getOrganizationData(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/users/me/organization`, { withCredentials: true })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    });
}

export function getGoalsList(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/goals`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    }); 
}

export function getBonusList(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/bonus`, { withCredentials: true })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    });
}

export function getBonusRulesList(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/bonus-rules`, { withCredentials: true })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    });
}

export function updateBonusData(bonusData, bonusId){
    return new Promise((resolve, reject) => {
        axios.patch(`${host}/bonus-rules/${bonusId}`, bonusData, { withCredentials: true })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export function createBonusAssignments(bonusId, teamsToAssign){
    return new Promise((resolve, reject) => {
        axios.post(`${host}/bonus-rules/${bonusId}/assignments`, teamsToAssign, { withCredentials: true })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    });
}

export function deleteBonus(bonusId){
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/bonus/${bonusId}`, { withCredentials: true })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    });
}

export function editOrganization(ongData){
    return new Promise((resolve, reject) => {
        axios.patch(`${host}/users/me/organization`, ongData, { withCredentials: true })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export function getTotalTimeDifference(list, language){
    let difference = 0;
    list.forEach(t => {
        let dt1 = moment(t.startTime);
        let dt2 = moment(t.endTime);
        difference += dt2.diff(dt1);
    })    
    
    if(!isNaN(difference)){
        let duration = moment.duration(difference); 

        let hours = duration.hours();
        let minutes = (duration.minutes() < 10 ? '0'+duration.minutes() : duration.minutes());
        let seconds = (duration.seconds() < 10 ? '0'+duration.seconds() : duration.seconds());

        return "<strong>"+hours+':'+minutes+':'+seconds+"</strong>";
    }else{
        return labels.getLabel("TimeInProgress", language);
    }
}

export function getTimesList(queryFilters){
    const filters = queryFilters || "";
    return new Promise((resolve, reject) => {
        axios.get(`${host}/work-times`+filters, {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.data.status === "success") 
                resolve(res.data.data);
        })
        .catch(error => 
            reject(error)
        )
    });
}

export function addTime(timeObj){
    return new Promise((resolve, reject) => {
        axios.put(`${host}/work-times`, timeObj, {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.data.status === "success") 
                resolve(res.data.data);
        })
        .catch(error => 
            reject(error)
        )
    });
}

export function validateDate(date){
    return moment(date).isValid();
}

export function validateTime(time){
    return moment(time, "LT", true).isValid();
}

export function compareDates(date1, date2, operator){
    if(operator === "eq")
        return moment(date1, "L", "pt").isSame(date2);
    else if(operator === "gte")
        return moment(date1, "L", "pt").isSameOrAfter(date2);
    else if(operator === "lte") 
        return moment(date1, "L", "pt").isSameOrBefore(date2);
    else
        return null;
}

export function compareTimes(time1, time2, operator){
    if(operator === "gt")
        return moment(time1, "LT", true).isAfter(moment(time2, "LT", true));
    else if(operator === "lt") 
        return moment(time1, "LT", true).isBefore(moment(time2, "LT", true));
    else
        return null;
}

export function getUsersList(filters){
    return new Promise((resolve, reject) => {
        const urlQuery = (filters && Object.keys(filters).map(f => 
            `filter[${f}]=${filters[f]}`
        ).join('&')) || '';
        axios.get(`${host}/users${urlQuery ? '?' : '' }${urlQuery}`, {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.data.status === "success") resolve(res.data.data);
        })
        .catch(error => reject(error))
    })
}

export function updateIndividualBonus(bonusId, bonusData){
    return new Promise((resolve, reject) => {
        axios.patch(`${host}/bonus/${bonusId}`, bonusData, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}

export function createReport(requestObj){
    return new Promise((resolve, reject) => {
        axios.post(`${host}/reports`, requestObj, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}

export function getAllTeams(showArchivedTeams){
    let query = `${host}/teams`;
    if(!showArchivedTeams){
        query += "?filter[isArchived][eq]=false"
    }
    return new Promise((resolve, reject) => {
        axios.get(query, {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if (res.status >= 200 && res.status < 300) resolve(res.data.data);
        })
        .catch(error => reject(error))
    })
}

export function getDonationsList(filters){
    const queryString = (filters) ? filters : "";
    return new Promise((resolve, reject) => {
        axios.get(`${host}/form-entries?include=recruiter,team,creator,emailStatus,lastModifier${queryString}`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            const { data, meta, status } = res.data;
            if(status === "success"){
                resolve({
                    data: data,
                    meta: meta,
                });
            }
        })
        .catch(error => reject(error))
    })
}

export function getDonationEntryObj(formEntry){
    const { createdDate, collectionDate, lastModifiedDate } = formEntry;
    const { recruiter, creator, team, emailStatus, lastModifier } = formEntry.relationships;
    const { latitude, longitude, status, collectionCity, collectionAddress, archived, manualLocation, collectionLocationSource } = formEntry.attributes;
    
    let otherFields = {};
    Object.keys(formEntry.attributes.formValues).forEach(field => {
        otherFields = {
            ...otherFields,
            [field] : formEntry.attributes.formValues[field]
        }
    });

    return {
        id: formEntry.id,
        location: latitude+', '+longitude,
        recruiter,
        team,
        creator,
        status,
        collectionCity,
        collectionAddress,
        manualLocation,
        collectionLocationSource,
        emailStatus,
        createdDate,
        donationDate : collectionDate,
        lastModifiedDate,
        lastModifier,
        archived,
        ...otherFields
    }
}

export function getSchemaErrorMessages(validationResult, language){
    let errors = {};
            
    validationResult.error && validationResult.error.details.forEach(e => {
        const name = e.path[0]; 
        const errorId = `${name}.${e.type}`;
        let errorMessage = labels.getLabel(errorId, language);
        if(e.type==="any.required" || e.type==="any.empty" || e.type==="any.allowOnly")
            errorMessage = labels.getLabel("mandatoryField", language, e.message);
        else if(e.type==="string.min" || e.type==="number.max")
            errorMessage = labels.getLabel(e.type, language, e.message, e.context.limit);
        else if(!errorMessage)
            errorMessage = labels.getLabel(e.type, language, e.message, e.context.label);

        errors = {
            ...errors, 
            [e.context.key] : errorMessage
        }
    });

    return errors
}

export function createGoal(goalObj){
    return new Promise((resolve, reject) => {
        axios.post(`${host}/goals`, goalObj, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.status === 200){
                resolve(res);
            }
        })
        .catch(error => reject(error));
    });
}

export function createGoalAssignments(goalId, teamsToAssign){
    return new Promise((resolve, reject) => {
        axios.post(`${host}/goals/${goalId}/assignments`, teamsToAssign, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}

export function updateGoal(id, goalData){
    return new Promise((resolve, reject) => {
        axios.patch(`${host}/goals/${id}`, goalData, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}

export function deleteReport(reportId){
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/reports/${reportId}`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}

export function getFormEntry(entryId, queries){
    return new Promise((resolve, reject) => {
        let query = '';
        if(queries && queries.length > 0){
            queries.forEach((q, index) => {
                query = (index === 0) 
                    ? '?include='+q 
                    : query + ','+q 
            });
        }
        axios.get(`${host}/form-entries/${entryId}${query}`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.data.status === "success") resolve(res.data.data);
        })
        .catch(error => reject(error))
    });
}

export function getPDFFormEntry(entryId, queries){
    return new Promise((resolve, reject) => {
        let query = '';
        if(queries && queries.length > 0){
            queries.forEach((q, index) => {
                query = (index === 0) 
                    ? '?include='+q 
                    : query + ','+q 
            });
        }
        axios.get(`${host}/pdf-form-entry/${entryId}${query}`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.data.status === "success") resolve(res.data.data);
        })
        .catch(error => reject(error))
    });
}

export function getCurrentSchedules(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/team-jobs`,  {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => resolve(res.data.data))
        .catch(error => reject(error));
    });
}

export function getDatesInRange(range){
    const today = new Date();

    if(range === "TODAY"){
        return {
            startDate : moment(today).startOf('day').toDate(),
            endDate : moment(today).endOf('day').toDate()
        }
    }else if(range === "YESTERDAY"){
        const yesterday = moment(today).subtract(1, 'days').toDate();
        return {
            startDate : yesterday,
            endDate : yesterday
        }
    }else if(range === "THIS_WEEK"){
        const firstDayWeek = moment(today).startOf('isoWeek').toDate();
        const lastDayWeek = moment(today).endOf('isoWeek').toDate();
        return {
            startDate : firstDayWeek,
            endDate : lastDayWeek
        }
    }else if(range === "PREVIOUS_WEEK"){
        const lastWeekStart = moment(today).subtract(1, 'weeks').startOf('isoWeek').toDate();
        const lastWeekEnd = moment(today).subtract(1, 'weeks').endOf('isoWeek').toDate();
        return {
            startDate : lastWeekStart,
            endDate : lastWeekEnd
        }
    }else if(range === "THIS_AND_PREVIOUS_WEEK"){
        const lastWeekStart = moment(today).subtract(1, 'weeks').startOf('isoWeek').toDate();
        const lastDayThisWeek = moment(today).endOf('isoWeek').toDate();
        return {
            startDate : lastWeekStart,
            endDate : lastDayThisWeek
        }
    }else if(range === "THIS_MONTH"){
        const firstDayMonth = moment(today).startOf('month').toDate();
        const lastDayMonth = moment(today).endOf('month').toDate();
        return {
            startDate : firstDayMonth,
            endDate : lastDayMonth
        }
    }else if(range === "PREVIOUS_MONTH"){
        const firstDayMonth = moment(today).subtract(1, 'month').startOf('month').toDate();
        const lastDayMonth = moment(today).subtract(1, 'month').endOf('month').toDate();
        return {
            startDate : firstDayMonth,
            endDate : lastDayMonth
        }
    }else if(range === "THIS_AND_PREVIOUS_MONTH"){
        const firstDayLastMonth = moment(today).subtract(1, 'month').startOf('month').toDate();
        const lastDayThisMonth = moment(today).endOf('month').toDate();
        return {
            startDate : firstDayLastMonth,
            endDate : lastDayThisMonth
        }
    }else if(range === "THIS_QUARTER"){
        const firstDayQuarter = moment(today).startOf('quarter').toDate();
        const lastDayQuarter = moment(today).endOf('quarter').toDate();
        return {
            startDate : firstDayQuarter,
            endDate : lastDayQuarter
        }
    }else if(range === "PREVIOUS_QUARTER"){
        const firstDayQuarter = moment(today).subtract(1, 'quarter').startOf('quarter').toDate();
        const lastDayQuarter = moment(today).subtract(1, 'quarter').endOf('quarter').toDate();
        return {
            startDate : firstDayQuarter,
            endDate : lastDayQuarter
        } 
    }else if(range === "THIS_AND_PREVIOUS_QUARTER"){
        const firstDayLastQuarter = moment(today).subtract(1, 'quarter').startOf('quarter').toDate();
        const lastDayThisQuarter = moment(today).endOf('quarter').toDate();
        return {
            startDate : firstDayLastQuarter,
            endDate : lastDayThisQuarter
        } 
    }else if(range === "THIS_YEAR"){
        const startYear = moment(today).startOf('year').toDate();
        const endYear = moment(today).endOf('year').toDate();
        return {
            startDate : startYear,
            endDate : endYear
        }
    }else if(range === "PREVIOUS_YEAR"){
        const startYear = moment(today).subtract(1, 'year').startOf('year').toDate();
        const endYear = moment(today).subtract(1, 'year').endOf('year').toDate();
        return {
            startDate : startYear,
            endDate : endYear
        }
    }else if(range === "THIS_AND_PREVIOUS_YEAR"){
        const startLastYear = moment(today).subtract(1, 'year').startOf('year').toDate();
        const endThisYear = moment(today).endOf('year').toDate();
        return {
            startDate : startLastYear,
            endDate : endThisYear
        }
    }else{
        return null;
    }
}

export function getFormatedRangeDates(dateRange){
    const dates = this.getDatesInRange(dateRange);
    
    if(dates === null){
        return null;
    }

    const startDate = dates.startDate;
    let startDateMonth = startDate.getMonth()+1;
    if(startDateMonth < 10) startDateMonth = '0'+startDateMonth;
    let startDateDay = startDate.getDate();
    if(startDateDay < 10) startDateDay = '0'+startDateDay;

    const endDate = dates.endDate;
    let endDateMonth = endDate.getMonth()+1;
    if(endDateMonth < 10) endDateMonth = '0'+endDateMonth;
    let endDateDay = endDate.getDate();
    if(endDateDay < 10) endDateDay = '0'+endDateDay;

    return {
        rangeStartDate : startDate.getFullYear()+'-'+startDateMonth+'-'+startDateDay+' 00:00:00',
        rangeEndDate : endDate.getFullYear()+'-'+endDateMonth+'-'+endDateDay+' 23:59:59'
    }
}

export function getUserData(username){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/users/${username}`, {withCredentials:true, headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.data.status === "success"){
                resolve(res.data.data[0]);
            }
        })
        .catch(error => reject(error) )
    });
}

export function getReportFoldersList(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/report-folders`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        })
    });
}

export function getUserGoalsChartData(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/users/me/goals`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        })
    });
}

export function getOrganizationChartsData(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/users/me/organization/goals`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        })
    });
}

export function getTeamChartsData(){
    return new Promise((resolve, reject) => {
        axios.get(`${host}/users/me/teams/goals`, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        })
    });
}

export function flatten(arr) {
    return Array.prototype.concat(...arr);
}